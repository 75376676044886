import { useAuth0 } from '@auth0/auth0-react';
import { useEffect, useState } from 'react';
import { matchPath, useNavigate } from 'react-router';
import 'simplebar-react/dist/simplebar.min.css';
import './App.scss';
import { Routes, Route, useLocation } from 'react-router-dom';
import ReactGA from 'react-ga4';
import { ThemeProvider } from '@mui/material/styles';
import { Box } from '@mui/material';
import SimpleBarReact from 'simplebar-react';
import Login from './components/Pages/Login';
import DeviceList from './components/Pages/DeviceList';
import TransCountList from './components/Pages/TransCountList';
import FromLangList from './components/Pages/FromLangList';
import ToLangList from './components/Pages/ToLangList';
import { PAGE_PATH_NAME } from './constants/constants';
import DeviceDeregister from './components/Pages/DeviceDeregister';
import DeviceDeregisterComplete from './components/Pages/DeviceDeregisterComplete';
import { theme } from './components/Templates/CommonStyle';
import Header from './components/Templates/Header';
import UserList from './components/Pages/UserList';
import UserDetail from './components/Pages/UserDetail';
import UserAdd from './components/Pages/UserAdd';
import Dashboard from './components/Pages/Dashboard';
import GroupAdd from './components/Pages/GroupAdd';
import GroupListPage from './components/Pages/GroupListPage';
import GroupDetailsPage from './components/Pages/GroupDetailsPage';
import PersonalSettings from './components/Pages/PersonalSettings';
import AuthRoute from './utils/authRoute';
import AdminPrivateRoute from './utils/ProtectRoute';
import UserPrivateRoute from './utils/PrivateRoute';
import ToasteMessage from './components/Atoms/Snackbar';
import WarningDialog2 from './components/Atoms/WarningDialog2';
import useAccountInfo from './hooks/useAccountInfo';
import callApiUtil from './utils/callApiUtil';
import DeviceDetailsPage from './components/Pages/DeviceDetails';
import { AppDispatch, useAppDispatch } from './state/store';
import { groupListSlice } from './state/slice/groupListSlice';
import { translationListSlice } from './state/slice/translationCountSlice';
import useAccessKey from './hooks/useAccessKey';
import CorporateProfilePage from './components/Pages/CorporateProfilePage';
import CheckResponse from './utils/appWrapper';
import NotFoundPage from './components/Pages/NotFoundPage';
import { decryptText } from './utils/utility';
import SecuritySettings from './components/Pages/SecuritySettings';
import useLocale from './hooks/useLocale';
import LoginErrorRoutePage from './components/Pages/LoginErrorRoutePage';
import { groupDetailsSlice } from './state/slice/groupDetailsSlice';

ReactGA.initialize(process.env.REACT_APP_GA_SECRET_KEY ?? 'G-WBCCSLWJX2');

const App = (): JSX.Element => {
  const { isLoading } = useAuth0();
  const [showNav, setShowNav] = useState<boolean>(true);
  const {
    accountInfo,
    accountoInfoReset,
    saveRoleChange,
    saveSubcriptionChange,
  } = useAccountInfo();
  const { localeLanguage } = useLocale();
  const { reset, accessKeyRedux } = useAccessKey();
  const location = useLocation();
  const navigate = useNavigate();

  useEffect(() => {
    const searchParams = new URLSearchParams(location.search);
    const error = searchParams.get('error');
    const errorDescription = searchParams.get('error_description');

    if (error) {
      navigate('/LOGIN_ERROR', {
        state: {
          message: 'OKTA_ERROR',
          oktaResponse: errorDescription,
        },
      });
      // void logout({ logoutParams: { returnTo: window.location.origin } })
      //   .then(() => {
      //     localStorage.removeItem('OktaToken');
      //   })
      //   .catch((err) => {
      //     console.log(err);
      //   })
      //   .finally(() => {
      //     localStorage.removeItem('OktaToken');
      //     displaySnackbar({
      //       message: errorDescription || 'Unknown Error',
      //       type: 'error',
      //       timeout: 17000,
      //     });
      //   });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  const dispatch: AppDispatch = useAppDispatch();

  /* eslint-disable */
  useEffect(() => {
    if (
      (localStorage.getItem('persist:root') === null ||
        !accessKeyRedux ||
        accessKeyRedux === '') &&
      window.location.pathname !== '/account' &&
      window.location.pathname !== '/login'
    ) {
      accountoInfoReset();
      reset();

      return;
    }
  }, [accessKeyRedux, localStorage.getItem('persist:root')]);

  useEffect(() => {
    const saveUserData = async () => {
      let localStorageData = '';
      if (localStorage.getItem('persist:root') !== null) {
        localStorageData = JSON.parse(
          JSON.parse(localStorage.getItem('persist:root')!).iotAccessKey,
        )!.iotAccessKey;
      }

      // if loged user change from another tab
      if (localStorageData !== '' || accessKeyRedux !== '') {
        callApiUtil.defaults.headers.common[
          'Console-Authorization'
        ] = `Console-Token ${decryptText(
          localStorageData === '' ? accessKeyRedux : localStorageData,
        )}`;
        callApiUtil.defaults.headers.common[
          'Auth0-Authorization'
        ] = `Auth0-Token ${localStorage.getItem('OktaToken')}`;
      } else {
        // for log in api send header empty
        callApiUtil.defaults.headers.common['Console-Authorization'] = '';
      }

      if (
        accessKeyRedux &&
        localStorageData &&
        localStorageData !== accessKeyRedux
      ) {
        saveRoleChange(false);
        saveSubcriptionChange(false);
      }
    };

    saveUserData();
  }, [accessKeyRedux, localStorage.getItem('persist:root')]);

  useEffect(() => {
    if (accountInfo) {
      ReactGA.set({ userId: accountInfo.userId });
      ReactGA.gtag('set', 'user_properties', {
        account_id: accountInfo.userId,
        role: accountInfo.roleName,
        corporate_id: accountInfo.corpId,
        corporate_name: accountInfo.company,
        language: accountInfo.locale,
        total_groups: accountInfo.totalGroups,
      });
    }
    document.documentElement.lang = localeLanguage;
  }, [accountInfo]);

  useEffect(() => {
    const totalBody = document.getElementsByClassName(
      'simplebar-content-wrapper',
    )[0];
    if (totalBody) {
      totalBody.scrollTop = 0;
    }
  }, [location.key]);

  document.addEventListener('visibilitychange', (event) => {
    let localStorageData = '';
    if (localStorage.getItem('persist:root') !== null) {
      localStorageData = JSON.parse(
        JSON.parse(localStorage.getItem('persist:root')!).iotAccessKey,
      )!.iotAccessKey;
    }

    if (document.visibilityState == 'visible') {
      // if loged user change from another tab

      if (
        localStorageData &&
        accessKeyRedux !== localStorageData &&
        (window.location.pathname === '/personalsetting' ||
          window.location.pathname === '/corporateprofile')
      ) {
        window.location.reload();
      }
      // if loged user change from another tab
      if (localStorageData && accessKeyRedux !== localStorageData) {
        callApiUtil.defaults.headers.common[
          'Console-Authorization'
        ] = `Console-Token ${decryptText(localStorageData)}`;
      }
      // if log out from another tab
      if (!localStorageData && accessKeyRedux) {
        window.location.reload();
      }
    }
  });
  const { isFromGroupDetailsPage, resetGroupListState } =
    groupListSlice.actions;
  const { isFromDeviceDetailsPage, resetDeviceListState } =
    translationListSlice.actions;
  const { resetGroupDetailsState } = groupDetailsSlice.actions;

  useEffect(() => {
    // this action is to check whether url matches with group detail page.
    const matchPathNameForGroupDetails = matchPath(
      { path: PAGE_PATH_NAME.GROUP_DETAIL },
      location.pathname,
    );
    const matchPathNameForDeviceDetails = matchPath(
      { path: PAGE_PATH_NAME.DEVICE_DETAILS },
      location.pathname,
    );
    // if url doesn't match then pageSrc state is set to false(this check is used in group list page to set previous narrowSearch text)
    if (matchPathNameForGroupDetails === null) {
      dispatch(isFromGroupDetailsPage(false));
      const matchPathNameForGroupList = matchPath(
        { path: PAGE_PATH_NAME.GROUPS },
        location.pathname,
      );
      if (matchPathNameForGroupList === null) {
        dispatch(resetGroupListState());
      }
    }

    if (matchPathNameForDeviceDetails === null) {
      dispatch(isFromDeviceDetailsPage(false));

      const matchPathNameForTranslationList = matchPath(
        { path: PAGE_PATH_NAME.TRANS_COUNT_LIST },
        location.pathname,
      );

      if (matchPathNameForTranslationList === null) {
        dispatch(resetDeviceListState());
      }
      if (matchPathNameForGroupDetails === null) {
        dispatch(resetGroupDetailsState());
      }
    }
  }, [location.pathname]);

  // to check sidebar
  const url = useLocation();
  const [showDrawer, setShowDrawer] = useState<boolean>(true);
  useEffect(() => {
    if (
      url.pathname === PAGE_PATH_NAME.CORPORATE_PROFILE ||
      url.pathname === PAGE_PATH_NAME.PERSONAL_SETTING
    ) {
      setShowDrawer(false);
    } else {
      setShowDrawer(true);
    }

    if (url.pathname === PAGE_PATH_NAME.BASE) {
      setShowNav(false);
    }

    if (url.pathname === PAGE_PATH_NAME.DASHBOARD) {
      setShowNav(true);
      // showNav will be false only if NotFoundPage render
    }
  }, [location.pathname]);

  return (
    <Box className="App" sx={{ display: 'flex' }}>
      <ThemeProvider theme={theme}>
        {accessKeyRedux && showNav && !isLoading && <Header />}
        <WarningDialog2 />
        <ToasteMessage />
        <SimpleBarReact
          className={`main-content ${
            accessKeyRedux && showDrawer && showNav ? 'responsive-content' : ''
          }`}
        >
          <CheckResponse.CheckResponse>
            <Routes>
              <Route
                path="*"
                element={<NotFoundPage setShowNav={setShowNav} />}
              />

              <Route path="LOGIN_ERROR" element={<LoginErrorRoutePage />} />

              <Route path="/" element={<Login />} />
              {/* <Route path={PAGE_PATH_NAME.DASHBOARD} element={<Login />} /> */}
              <Route element={<AuthRoute />}>
                <Route
                  path={PAGE_PATH_NAME.DASHBOARD}
                  element={<Dashboard />}
                />

                <Route
                  path={PAGE_PATH_NAME.DEVICE_LIST}
                  element={<DeviceList />}
                />
                <Route
                  path={PAGE_PATH_NAME.DEVICE_DETAILS}
                  element={<DeviceDetailsPage />}
                />
                <Route
                  path={PAGE_PATH_NAME.TRANS_COUNT_LIST}
                  element={<TransCountList />}
                />
                <Route
                  path={PAGE_PATH_NAME.FROM_LANG_LIST}
                  element={<FromLangList />}
                />
                <Route
                  path={PAGE_PATH_NAME.TO_LANG_LIST}
                  element={<ToLangList />}
                />

                {/* <Route path={PAGE_PATH_NAME.SETTING_INFO} element={<SettingInfo />} /> */}
                <Route
                  path={PAGE_PATH_NAME.DEVICE_DEREGISTER}
                  element={<DeviceDeregister />}
                />
                <Route
                  path={PAGE_PATH_NAME.DEVICE_DEREGISTER_COMPLETE}
                  element={<DeviceDeregisterComplete />}
                />
                <Route
                  path={PAGE_PATH_NAME.USER_DETAIL}
                  element={<UserDetail />}
                />
                <Route path={PAGE_PATH_NAME.USER_ADD} element={<UserAdd />} />
                <Route
                  path={PAGE_PATH_NAME.PERSONAL_SETTING}
                  element={<PersonalSettings />}
                />

                {/* Only Admin can access */}
                <Route element={<AdminPrivateRoute />}>
                  <Route
                    path={PAGE_PATH_NAME.ADD_GROUP}
                    element={<GroupAdd />}
                  />
                  <Route
                    path={PAGE_PATH_NAME.CORPORATE_PROFILE}
                    element={<CorporateProfilePage />}
                  />
                  <Route
                    path={PAGE_PATH_NAME.USER_LIST}
                    element={<UserList />}
                  />
                  <Route
                    path={PAGE_PATH_NAME.SECURITY_SETTINGS}
                    element={<SecuritySettings />}
                  />
                </Route>

                {/* Only User & above can access */}
                <Route element={<UserPrivateRoute />}>
                  <Route
                    path={PAGE_PATH_NAME.GROUP_DETAIL}
                    element={<GroupDetailsPage />}
                  />
                  <Route
                    path={PAGE_PATH_NAME.GROUPS}
                    element={<GroupListPage />}
                  />
                </Route>
              </Route>
            </Routes>
          </CheckResponse.CheckResponse>
        </SimpleBarReact>
      </ThemeProvider>
    </Box>
  );
};

export default App;
