/* eslint-disable import/prefer-default-export */
import { createTheme, Theme } from '@mui/material/styles';
import { makeStyles } from '@mui/styles';
import { grey } from '@mui/material/colors';

const headerHeight = '62px'; // ヘッダー高さ
const drawerWidth = 250; // ドロワーメニュー幅
const listContentWidth = '1300px'; // 一覧画面の共通表示幅
const widthPercent = '60%'; // 共通表示幅
const reportContainerWidth = `calc(${widthPercent} + 64px)`; // レポートコンテンツの共通表示幅
const mainBackgroundColor = '#f8f8f8'; // メイン背景色
// const rootMaxWidth = '1400px'; // rootコンテンツの最大幅
const rootMaxWidth = '100%'; // rootコンテンツの最大幅
const remotDeviceSetupDialogHeight = 'calc(100vh -  50px)';

/**
 * 本システムのテーマ
 */
export const theme: Theme = createTheme({
  typography: {
    // fontFamily: window.navigator.language.includes('ja')
    //   ? ['Noto Sans JP'].join(',')
    //   : ['Noto Sans'].join(','),
    fontFamily: ['Noto Sans JP', 'Noto Sans'].join(','),
    h5: {
      fontWeight: 'bolder',
    },
  },
  palette: {
    primary: { main: '#e6e6e6' }, // テーマの色
    secondary: {
      main: grey[200],
    },
    background: {
      default: '#f8f8f8',
    },
    text: {
      primary: '#313133',
    },
    success: {
      main: '#4DC176',
    },
    error: {
      main: '#E1341E',
    },
  },
  components: {
    MuiTooltip: {
      styleOverrides: {
        arrow: {
          color: '#313133',
        },
        tooltip: {
          backgroundColor: '#313133',
        },
      },
    },
    // Name of the component
    MuiButtonBase: {
      defaultProps: {
        // The props to change the default for.
        disableRipple: true, // to stop global ripple
      },
    },
    MuiButton: {
      styleOverrides: {
        // Name of the slot
        root: {
          // Some CSS
          boxShadow: 'none',
          '&:hover': {
            boxShadow: 'none',
          },
        },
      },
    },
  },
});

/**
 * 本システムの共通スタイル
 */
export const useStyles = makeStyles(() => ({
  root: {
    backgroundColor: mainBackgroundColor,
    minWidth: '950px',
    '@media screen and (max-width: 1200px)': {
      minWidth: 'unset',
      width: '100%',
    },
  },
  groupFeatureContainer: {
    padding: '30px 40px 32px 40px',
    maxWidth: '100%',
    height: '100%',
    '@media screen and (max-width:1440px)': {
      padding: '30px 30px 25px 30px',
    },
    '@media screen and (max-width:1336px)': {
      padding: '30px 20px 15px 20px',
    },
  },
  // group table start
  tableCard: {
    minHeight: 'calc(100vh - 257px)',
    backgroundColor: '#FFFFFF',
    border: '0.5px solid rgba(217, 217, 217, .5)',
    borderRadius: '8px',
    padding: '20px 30px 30px',
    marginTop: '12px',
    '@media screen and (max-width:1440px)': {
      minHeight: 'calc(100vh - 247px)',
    },
    '@media screen and (max-width:1336px)': {
      minHeight: 'calc(100vh - 237px)',
    },
    '@media screen and (max-width: 599px)': {
      paddingLeft: '16px',
      paddingRight: '16px',
    },
  },
  // group table end
  // group device table start
  tableDeviceCard: {
    minHeight: 'calc(100vh -  300px)',
    // minHeight: 'calc(100vh -  305px)',
    backgroundColor: '#FFFFFF',
    border: '0.5px solid rgba(217, 217, 217, .5)',
    borderRadius: '8px',
    padding: '20px 30px 30px',
    marginTop: '12px',
  },
  // group device table end
  rootList: {
    backgroundColor: mainBackgroundColor,
    // minWidth: rootMaxWidth,
  },
  toolbarIcon: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-end',
    padding: '0 8px',
    ...theme.mixins.toolbar,
  },
  submitButton: {
    '& .MuiButtonBase-root': {
      backgroundColor: '#008ee7',
      color: '#fff',
      position: 'relative',

      '&:hover': {
        backgroundColor: '#3aa4e7',
      },
    },
  },
  goBackButton: {
    '& .MuiButtonBase-root': {
      backgroundColor: '#fff',

      '&:hover': {
        backgroundColor: '#e6e6e6',
      },
    },
  },
  addUserGoBackButton: {
    marginRight: '8px',
    '& .MuiButtonBase-root': {
      border: '1px solid',
      borderRadius: '6px',
      borderColor: '#D9D9D9',
      backgroundColor: '#FFFFFF',

      '&:hover': {
        backgroundColor: ' rgba(0, 0, 0, 0.04)',
      },
      '&.Mui-disabled': {
        color: 'white',
        background: '#FFFFFF',
        opacity: 0.5,
      },
    },
  },
  addUserSubmitButton: {
    '& .MuiButtonBase-root': {
      backgroundColor: '#009DE1',
      color: '#fff',
      position: 'relative',

      '&:hover': {
        backgroundColor: '#3aa4e7',
      },
    },
  },
  userDeleteButton: {
    '& .MuiButtonBase-root': {
      backgroundColor: '#fff',
      color: '#cc141a',

      '&:hover': {
        backgroundColor: '#e6e6e6',
      },
    },
  },
  appBar: {
    '& .MuiPaper-root': {
      height: headerHeight,
      background: '#FFFFFF',
      zIndex: theme.zIndex.drawer + 1,
      transition: theme.transitions.create(['width', 'margin'], {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.leavingScreen,
      }),
    },
  },
  headerItem: {
    component: 'h1',
    variant: 'subtitle1',
    color: 'inherit',
    textAlign: 'right',
  },
  menuButton: {
    '& .MuiButtonBase-root': {
      borderRadius: '5px',
      '&:hover': {
        backgroundColor: 'rgba(118, 131, 145, 0.3)',
      },
    },
  },
  title: {
    textAlign: 'center',
    padding: '3px 0',
    margin: '0 auto',
  },
  loggedInPageTitle: {
    textAlign: 'left',
    paddingLeft: '24px',
    padding: '3px 0',
    margin: '0 auto',
  },
  deviceListPageTitle: {
    textAlign: 'left',
    padding: '0px',
  },
  drawerPaper: {
    position: 'relative',
    whiteSpace: 'nowrap',
    width: drawerWidth,
    marginTop: headerHeight,
    transition: theme.transitions.create('width', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
    '&&': {
      backgroundColor: '#e3e7e8',
    },
  },
  drawerPaperClose: {
    overflowX: 'hidden',
    transition: theme.transitions.create('width', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
    width: theme.spacing(7),
    [theme.breakpoints.up('sm')]: {
      width: theme.spacing(9),
    },
  },
  listContent: {
    flexGrow: 1,
    minHeight: 'calc(100vh-300)',
    '& .MuiContainer-root': {
      // minWidth: listContentWidth,
      maxWidth: rootMaxWidth,
    },
  },
  content: {
    flexGrow: 1,
    minHeight: '100vh',
  },
  container: {
    // paddingTop: 82, // ヘッダーの分も加えて下げる(marginだとスクロールが発生するため)
    paddingTop: '20px', // top create gap beetween appbar and content
    paddingBottom: theme.spacing(4),
  },
  deviceContainer: {
    // paddingTop: 82, // ヘッダーの分も加えて下げる(marginだとスクロールが発生するため)
    paddingBottom: theme.spacing(4),
    paddingLeft: '40px',
    paddingRight: '40px',
    paddingTop: '4px',
    '@media screen and (max-width:1440px)': {
      paddingLeft: '30px',
      paddingRight: '30px',
    },
    '@media screen and (max-width:1336px)': {
      paddingLeft: '20px',
      paddingRight: '20px',
    },
  },
  paper: {
    padding: theme.spacing(2),
    display: 'flex',
    overflow: 'auto',
    flexDirection: 'column',
  },
  settingLink: {
    '& .MuiListItemButton-root': {
      borderTop: 'solid 1px #e6e6e6',
      position: 'fixed',
      bottom: 0,
      width: drawerWidth,
    },
  },
  searchInput: {
    borderColor: '#adb5bd',
    '&:focus': {
      borderColor: '#dee2e6',
      boxShadow: 'none',
    },
  },
  clearButton: {
    width: '100px',
    border: 'none',
    boxShadow:
      '0px 3px 1px -2px rgb(0 0 0 / 20%), 0px 2px 2px 0px rgb(0 0 0 / 14%), 0px 1px 5px 0px rgb(0 0 0 / 12%)',
    '&:hover': {
      background: '#adb5bd',
    },
    '&:active': {
      background: '#e0e0e0',
    },
    '&:focus': {
      boxShadow:
        '0px 3px 1px -2px rgb(0 0 0 / 20%), 0px 2px 2px 0px rgb(0 0 0 / 14%), 0px 1px 5px 0px rgb(0 0 0 / 12%)',
    },
  },
  accountRegistedTable: {
    width: 552,
    '&:last-child td': {
      minWidth: '120px',
    },
    '&:last-child th, &:last-child td': {
      borderBottom: 0,
      paddingTop: 5,
      paddingBottom: 5,
      paddingRight: 10,
      paddingLeft: 10,
    },
  },
  periodContainer: {
    display: 'flex',
    marginBottom: '20px',
    maxWidth: '1200px',
    marginLeft: 'auto',
    marginRight: 'auto',
  },
  periodBox: {
    padding: '4px 8px',
    border: '1px solid #d9d9d9',
    borderLeft: 'none',
    fontSize: '14px',
    display: 'flex',
    alignItems: 'center',
    cursor: 'pointer',
    transition: 'background-color 0.3s ease',
    '@media screen and (max-width:800px)': {
      padding: '4px 6px',
      fontSize: '13px',
    },
    '@media screen and (max-width:600px)': {
      fontSize: '12px',
    },
    '&:first-child': {
      borderLeft: '1px solid #d9d9d9',
      paddingLeft: '12px',
      paddingRight: '12px',
      borderBottomLeftRadius: '4px',
      borderTopLeftRadius: '4px',
      '@media screen and (max-width:800px)': {
        paddingLeft: '8px',
        paddingRight: '8px',
      },
    },
    '&:last-child': {
      borderBottomRightRadius: '4px',
      borderTopRightRadius: '4px',
    },
  },
  active: {
    background: 'white',
  },
  disabled: {
    cursor: 'not-allowed',
    opacity: '0.7',
  },
  accountRegistedTableGrid: {
    width: 552,
  },
  tableRowNumberSelect: {
    backgroundColor: '#fff',
    marginRight: '1rem',
    width: '100px',
    height: '36px',
    '& .MuiSelect-select': {
      marginLeft: '-4px',
      overflow: 'visible',
    },
    '& img': {
      marginLeft: '-60px',
    },
    '& .MuiOutlinedInput-notchedOutline': {
      top: 0,
      '& .css-nnbavb': {
        display: 'none',
      },
    },
  },
  filterTilde: {
    minWidth: '30px',
  },
  filterWidth: {
    width: widthPercent,
  },
  filterInput: {
    width: '100%',
    backgroundColor: '#fff',
    '& .MuiOutlinedInput-input': {
      padding: '4px 16px',
      height: 28,
    },
    '& .MuiOutlinedInput-notchedOutline': {
      top: 0,
      '& .css-nnbavb': {
        display: 'none',
      },
    },
  },
  dateInput: {
    width: '11rem',
  },
  formControl: {
    margin: theme.spacing(1),
    width: 300,
  },
  indeterminateColor: {
    color: '#f50057',
  },
  selectAllText: {
    fontWeight: 500,
  },
  selectedAll: {
    backgroundColor: 'rgba(0, 0, 0, 0.08)',
    '&:hover': {
      backgroundColor: 'rgba(0, 0, 0, 0.08)',
    },
  },
  dialogButtonContainer: {
    display: 'flex',
    justifyContent: 'center',
    marginBottom: '10px',
  },
  downloadDialogButton: {
    '& .MuiButtonBase-root': {
      borderRadius: 0,
    },
  },
  cancelDialogButton: {
    '& .MuiButtonBase-root': {
      backgroundColor: '#fff',

      '&:hover': {
        backgroundColor: '#e6e6e6',
      },
    },
  },
  logDataDownloadDialogMessage: {
    textAlign: 'center',
    margin: '1.5rem auto 1rem auto',
    '& p': {
      margin: 0,
      width: '400px',
      textAlign: 'left',
      wordWrap: 'break-word',
    },
  },
  dialogErrorMessage: {
    display: 'flex',
    alignItems: 'center',
    textAlign: 'center',
    height: 65,
    width: 450,
    '& .css-zaqzge': {
      padding: '8px 1rem',
      margin: 'auto',

      '& p': {
        textAlign: 'left',
      },
    },

    '&:empty': {
      height: 65,
      width: 450,
    },
  },
  downloadButton: {
    height: '100%',
    display: 'block',
    textDecoration: 'none',
    textAlign: 'center',
    backgroundColor: '#e0e0e0',
    borderRadius: 4,
    boxShadow:
      '0px 3px 1px -2px rgb(0 0 0 / 20%), 0px 2px 2px 0px rgb(0 0 0 / 14%), 0px 1px 5px 0px rgb(0 0 0 / 12%)',

    '&:hover': {
      backgroundColor: 'rgb(156, 156, 156)',
      boxShadow:
        '0px 2px 4px -1px rgb(0 0 0 / 20%), 0px 4px 5px 0px rgb(0 0 0 / 14%), 0px 1px 10px 0px rgb(0 0 0 / 12%)',
    },

    '& p': {
      display: 'flex',
      margin: 0,
      justifyContent: 'center',
      alignItems: 'center',
      height: '100%',
      color: 'rgba(0, 0, 0, 0.87)',
      fontSize: '0.875rem',
    },
  },
  reportContainer: {
    display: 'flex',
    textAlign: 'center',
    alignItems: 'center',
    width: reportContainerWidth,
    justifyContent: 'space-between',
  },
  datePickerContainer: {
    display: 'flex',
    textAlign: 'center',
    alignItems: 'center',
    width: '230px',
  },
  reportDownloadContainer: {
    display: 'flex',
    textAlign: 'center',
    alignItems: 'center',
    justifyContent: 'end',
  },
  reportRowBtn: {
    border: '1px solid #d9d9d9',
    width: '104px',
    height: '38px',
    backgroundColor: '#fff',
    borderRadius: '6px',
    fontSize: '14px',
    color: '#313133',
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    padding: '9px 10px',
    textAlign: 'left',
    '&:hover': {
      backgroundColor: 'transparent',
    },
    '& svg': {
      marginTop: '2px',
      width: '9px',
      height: '5px',
    },
  },
  reportRowMenu: {
    backgroundColor: '#fff',
    boxShadow: '0px 3px 6px #00000029',
    width: '104px',
    borderRadius: '6px',
    zIndex: 1300,
    '& .MuiList-root': {
      paddingTop: 0,
      paddingBottom: 0,
      '& .MuiListItem-root': {
        padding: '5px 12.5px',
        fontSize: '13px',
        justifyContent: 'end',
        color: '#313133',
        '&:hover': {
          backgroundColor: '#EAEAEB',
        },
      },
      '& .MuiListItem-root:first-child': {
        borderTopRightRadius: '6px',
        borderTopLeftRadius: '6px',
      },
      '& .MuiListItem-root:not(:last-child)': {
        borderBottom: '0.5px solid rgba(217, 217, 217, .5)',
      },
    },
    '&[data-popper-placement*="top"]': {
      boxShadow: '0px -3px 6px #00000029',
      '&:before': {
        bottom: '-12px',
      },
    },
    '&[data-popper-placement*="bottom"]:before': {
      top: '0',
    },
  },
  datePickerDialogContainer: {
    display: 'flex',
    textAlign: 'center',
    alignItems: 'center',
    margin: '0 auto',
  },
  transHistorySearchContainer: {
    display: 'flex',
    alignItems: 'center',
  },
  // start Password Strength Bars
  passStrengthBars: {
    marginTop: '-2px',
    display: 'flex',
    gap: '4px',
    justifyContent: 'space-between',
  },
  regPassStrengthBars: {
    marginTop: '-2px',
    display: 'flex',
    gap: '2px',
    justifyContent: 'space-between',
  },

  strengthBarFirst: {
    borderRadius: '3px 0px 0px 3px',
  },
  strengthBarLast: {
    borderRadius: '0px 3px 3px 0px',
  },
  strengthBar: {
    margin: '0px',
    height: '3px !important',
    width: '100%',
    backgroundColor: '#EDEEEF',
    opacity: 1,
    borderTop: '0px',
  },
  weakStrengthBar: {
    margin: '0px',
    width: '100%',
    height: '3px !important',
    backgroundColor: '#E1341E',
    opacity: 1,
    borderTop: '0px',
  },
  goodStrengthBar: {
    margin: '0px',
    width: '100%',
    height: '3px !important',
    backgroundColor: '#ffad00',
    opacity: 1,
    borderTop: '0px',
  },
  strongStrengthBar: {
    margin: '0px',
    width: '100%',
    height: '3px !important',
    backgroundColor: '#00A73B',
    opacity: 1,
    borderTop: '0px',
  },
  // end Password Strength Bars
  details: {
    marginBottom: '1rem',
    '& p': {
      margin: 0,
      textAlign: 'left',
    },
  },
  passwordResetSendMailContainer: {
    display: 'flex',
  },
  passwordResetSendMailMessage: {
    margin: '0 auto',
    '& p': {
      margin: 0,
      textAlign: 'left',
    },
  },
  passwordResetSendMailButtonContainer: {
    display: 'flex',
    justifyContent: 'center',
  },
  buttonDisplayLeft: {
    paddingRight: '1rem',
    '& button': {
      width: 116,
    },
  },
  buttonDisplayRight: {
    paddingLeft: '1rem',
    '& button': {
      width: 116,
    },
  },
  dateRangeErrorMessage: {
    textAlign: 'left',

    '&:empty': {
      height: 40,
    },
  },
  reportDownloadButton: {
    width: 200,

    '&.MuiButtonBase-root': {
      color: '#000',
      backgroundColor: '#fff',
      borderColor: '#bdbdbd',
      '&:hover': {
        borderColor: '#313133',
      },
    },
  },
  searchButton: {
    '&.MuiButtonBase-root': {
      color: '#000',
      backgroundColor: '#fff',
      borderColor: '#bdbdbd',
    },
  },
  textInput: {
    '& .MuiInputLabel-root': {
      top: '-9px', // 未入力時にラベル位置が下にずれるため

      '&.Mui-focused': {
        top: 0, // フォーカス時にラベル位置が上にずれるため
        color: 'rgba(0, 0, 0, 0.6)',
      },
      '&.MuiFormLabel-filled': {
        top: 0, // フォーカス時にラベル位置が上にずれるため
      },
    },
    // フェーズ4で[端末登録解除]ボタンを表示しないため、表示エリアいっぱいに入力欄を表示できるようにコメントアウト
    // '& .MuiInputBase-root': {
    //   width: '30rem',
    // },
    '& .MuiOutlinedInput-input': {
      padding: '4px 16px',
      height: 28,
      backgroundColor: '#fff',
      borderRadius: '4px',
    },
  },
  deviceNameTextInput: {
    '& .MuiInputBase-root': {
      width: '400px',
    },
    '& .MuiOutlinedInput-input': {
      padding: '4px 16px',
      height: 28,
      backgroundColor: '#fff',
      borderRadius: '4px',
    },
    '& .MuiOutlinedInput-notchedOutline': {
      top: 0,
      '& .css-nnbavb': {
        display: 'none',
      },
    },
  },
  deviceDetailButton: {
    width: 116,
  },
  userAddButtons: {
    borderRadius: '6px',
    minWidth: '118px',
    height: '44px',
  },
  deviceDeregisterTable: {
    width: 552,
    '&:last-child td': {
      borderBottom: 0,
      paddingTop: 5,
      paddingBottom: 5,
      paddingRight: 10,
      paddingLeft: 10,
    },
    '&:last-child .MuiTableCell-head': {
      width: '210px',
    },
  },
  ontimeInvalidContainer: {
    display: 'flex',
  },
  ontimeInvalid: {
    margin: '0 auto',
    textAlign: 'left',
  },
  notLoginTextInput: {
    '& .MuiInputLabel-root': {
      '&.Mui-focused': {
        color: 'rgba(0, 0, 0, 0.6)',
      },
    },
    '& .MuiOutlinedInput-input': {
      backgroundColor: '#fff',
      borderRadius: '4px',
    },
    '& .MuiFormHelperText-root': {
      whiteSpace: 'pre',
    },
  },
  noticeDialogMessage: {
    textAlign: 'center',
    margin: '1.5rem  1rem 1rem 1rem',
    '& p': {
      margin: 0,
      textAlign: 'left',
      wordWrap: 'break-word',
    },
  },
  loginPassword: {
    '& .MuiFormControl-root': {
      marginBottom: 0,
    },
  },
  passwordReminder: {
    textAlign: 'left',

    '& button': {
      color: '#007bff',
      textAlign: 'left',
      border: 'none',
      fontSize: '0.875rem',
      background: 'transparent',

      '&:hover': {
        color: '#0056b3',
        textDecoration: 'underline',
      },
    },
  },
  passwordReEnter: {
    '& .MuiTextField-root': {
      marginTop: 0,
    },
  },
  authList: {
    textAlign: 'left',
    width: '230px',
  },
  userDeleteDialogTable: {
    '& table': {
      display: 'flex',
      justifyContent: 'center',
    },
  },
  passwordResetDialogMessage: {
    textAlign: 'center',
    margin: '0.5rem auto 1rem auto',
    '& p': {
      margin: 0,
      textAlign: 'left',
      wordWrap: 'break-word',
    },
  },
  userDeleteDialogMessage: {
    textAlign: 'center',
    margin: '1.5rem auto 1rem auto',
    '& p': {
      margin: 0,
      maxWidth: '410px',
      textAlign: 'left',
      wordWrap: 'break-word',
    },
  },
  // transHistoryDialog: {
  //   '& .MuiPaper-root': {
  //     background: mainBackgroundColor,
  //     minWidth: '1000px',
  //     maxWidth: rootMaxWidth,
  //   },
  //   '& .MuiDialogTitle-root': {
  //     textAlign: 'right',
  //     padding: '16px 24px 0 24px',
  //   },
  //   '& .MuiIconButton-root': {
  //     marginRight: '8px'
  //   },
  //   '& .MuiDialogContent-root': {
  //     borderTop: 'none',
  //     borderBottom: 'none',
  //     padding: '0px 24px 24px 24px',
  //   },
  // },
  transHistoryContainer: {
    marginTop: '30px',
    minWidth: listContentWidth,
    '& .MuiTextField-root': {
      margin: 0,
    },
  },
  deviceDetailContainer: {
    minWidth: listContentWidth,
    fontWeight: 'bold',
    '& .MuiTextField-root': {
      margin: 0,
    },
  },
  deviceDetailViewReportOnlyContainer: {
    minWidth: '950px',
    fontWeight: 'bold',
    textAlign: 'center',
    '& .MuiTextField-root': {
      margin: 0,
    },
  },
  /**
   * code by bjit start
   */
  // app toolbar design
  appbarToolbar: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    backgroundColor: '#fff',
    minHeight: '62px',
    paddingLeft: '71px',
    paddingRight: '40px',
    '@media screen and (max-width: 599px)': {
      paddingLeft: '16px !important',
      paddingRight: '16px !important',
    },
  },
  logo: {
    height: '25px',
    alignSelf: 'start',
    verticalAlign: 'inherit',
    // marginTop: '16px',
  },
  loginScreenLogo: {
    height: '25px',
    alignSelf: 'start',
    // verticalAlign: 'inherit',
    marginTop: '16px',
  },
  // for appbar notification start
  headerIcon: {
    padding: '8px',
    marginLeft: '4px',
  },
  notificationBadge: {
    '& .MuiBadge-badge': {
      backgroundColor: '#E1341E',
      minWidth: '18px',
      height: '18px',
      color: '#fff',
      top: '-1px',
      fontSize: '8px',
      lineHeight: '12px',
    },
  },
  notificationMenu: {
    backgroundColor: '#fff',
    boxShadow: '0px 0px 5px #00000029',
    width: '453px',
    borderRadius: '6px',
    zIndex: 1300,

    '&:before': {
      content: '""',
      display: 'block',
      position: 'absolute',
      top: '6px',
      right: '50px',
      width: '18px',
      height: '18px',
      boxShadow: '0px 0px 5px #00000029',
      backgroundColor: '#fff',
      transform: 'translateY(-50%) rotate(45deg)',
      zIndex: -1,
    },
    '& .MuiList-root': {
      borderRadius: '6px',
    },
  },
  notificationMenuList: {
    backgroundColor: '#fff',
    padding: '24px 15px',
  },
  notificationMenuHeader: {
    justifyContent: 'space-between',
    padding: '4px 15px 0px 15px',
  },
  listItemSwap: {
    width: '393px',
    borderBottom: '0.5px solid #D9D9D9',
    margin: '8px 15px 10px 15px',
    padding: '0px',
  },
  listItemSwapBtn: {
    textTransform: 'none',
    padding: '8px 20px',
  },
  listItemSwapBtnText: {
    fontSize: '13px',
    // fontWeight: '500',
  },
  activityListItem: {
    padding: '12px 15px',
    width: '423px',
    // alignItems: 'center',
    borderRadius: '4px',
    '&:hover': {
      backgroundColor: '#F8F8F8',
      cursor: 'default',
    },
  },
  notificationIcon: {
    width: '35px',
    height: '35px',
  },
  notificationListImg: {
    width: '35px',
    height: '35px',
    borderRadius: '50%',
  },
  notificationListAvatar: {
    width: '35px',
    height: '35px',
    fontSize: 14,
  },
  notificationActivityTitle: {
    fontSize: '12px',
    lineHeight: '18px',
    marginBottom: '2px',
    width: '343px',
    wordBreak: 'break-word',
  },
  notificationActivityDetailText: {
    fontSize: '12px',
    lineHeight: '18px',
    marginBottom: '4px',
    width: '343px',
    whiteSpace: 'pre-wrap',
    wordBreak: 'break-word',
    '& a': {
      textDecoration: 'none',
      color: '#009DE1',
      '&:hover': {
        color: '#007EB4',
      },
    },
  },
  notificationActivityText: {
    fontSize: '12px',
    lineHeight: '18px',
    marginBottom: '4px',
    width: '343px',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    display: '-webkit-box',
    WebkitLineClamp: 2,
    WebkitBoxOrient: 'vertical',
    whiteSpace: 'pre-wrap',
    wordBreak: 'break-word',
    '& a': {
      textDecoration: 'none',
      color: '#009DE1',
      '&:hover': {
        color: '#007EB4',
      },
    },
  },
  notificationTitleTextLeft: {
    fontSize: '18px',
    fontWeight: '500',
  },
  notificationTitleTextRight: {
    fontSize: '12px',
    fontWeight: '300',
    color: '#939598',
  },
  activeBar: {
    position: 'absolute',
    height: '2px',
    background: '#009DE1',
    margin: '0px',
    opacity: 1,
    borderTop: '0px',
    bottom: '-1px',
  },
  noNotificationTextPrimary: {
    fontSize: '16px',
    lineHeight: '25px',
    marginTop: '-8px',
  },
  noNotificationTextSecondary: {
    fontSize: '14px',
    fontWeight: '300',
    lineHeight: '25px',
    color: '#939598',
  },
  notyIconBtn: {
    padding: '0px',
    marginLeft: '6px',
    borderRadius: '30%',
    color: '#009DE1',
    '&:hover': {
      color: '#007EB4',
      '& svg': {
        '& path': {
          fill: '#007EB4',
        },
      },
    },
  },
  notyBtnText: {
    fontSize: '10px',
    lineHeight: '9px',
    marginRight: '4.5px',
  },
  markAsReadBtn: {
    padding: '0px',
    marginLeft: '6px',
    borderRadius: '30%',
    color: '#939598',
    '&:hover': {
      color: '#6B6D6E',
    },
  },
  notificationAnnouncementText: {
    fontSize: '12px',
    lineHeight: '18px',
    marginBottom: '4px',
    width: '388px',
    whiteSpace: 'pre-wrap',
    wordBreak: 'break-word',
    '& a': {
      textDecoration: 'none',
      color: '#009DE1',
      '&:hover': {
        color: '#007EB4',
      },
    },
  },
  notificationAnnouncementDoubleLineText: {
    fontSize: '12px',
    lineHeight: '18px',
    marginBottom: '4px',
    width: '388px',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    display: '-webkit-box',
    WebkitLineClamp: 2,
    WebkitBoxOrient: 'vertical',
    whiteSpace: 'pre-wrap',
    wordBreak: 'break-word',
    '& a': {
      textDecoration: 'none',
      color: '#009DE1',
      '&:hover': {
        color: '#007EB4',
      },
    },
  },

  notificationLoaderBox: {
    margin: '20px 0px',
    height: 'calc(100vh -  225px)',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
  notificationLoaderBoxBottom: {
    margin: '20px 0px',
    left: '48%',
    display: 'flex',
    justifyContent: 'center',
  },
  // for appbar notification end
  // for appbar account start
  remoteNDelete: {
    backgroundColor: '#fff',
    boxShadow: '0px 3px 6px #00000029',
    // minWidth: '193px',
    border: '0.5px solid rgba(217, 217, 217, .5)',
    borderRadius: '4px',
    zIndex: 1300,
    color: '#313133',
    fontSize: '14px',
    lineHeight: '16px',
    opacity: 1,
    '& .MuiList-root': {
      paddingTop: 0,
      paddingBottom: 0,
      '& .MuiListItemButton-root:not(:last-child)': {
        borderBottom: '0.5px solid rgba(217, 217, 217, .5)',
      },
      '& .MuiListItemButton-root': {
        display: 'flex',
        // alignItems: 'center',
        gap: '5px',
        padding: '7px 14px 8px 14px',
        '& svg': {
          width: '20px',
          height: '20px',
        },
      },
    },
  },
  accountMenu: {
    backgroundColor: '#fff',
    boxShadow: '0px 3px 6px #00000029',
    width: '153px',
    borderRadius: '6px',
    zIndex: 1300,
    '&:before': {
      content: '""',
      display: 'block',
      position: 'absolute',
      top: '5px',
      right: '17px',
      width: '21px',
      height: '21px',
      backgroundColor: '#fff',
      transform: 'translateY(-50%) rotate(45deg)',
    },
    '& .MuiList-root': {
      paddingTop: 0,
      paddingBottom: 0,
      '& .MuiListItemButton-root': {
        padding: '10px 12px',
        // color: '#939598',
        color: '#313133',
        fontSize: '12px',
        lineHeight: '16px',
        '&:hover': {
          backgroundColor: '#EAEAEB',
        },
      },
      '& .MuiListItemButton-root:last-child': {
        paddingBottom: '9px',
      },
    },
    '& .MuiListItemButton-root:first-child': {
      borderTopLeftRadius: '6px',
      borderTopRightRadius: '6px',
    },
    '& .MuiListItemButton-root:not(:last-child)': {
      borderBottom: '1px solid #d9d9d9',
    },
  },
  accountName: {
    position: 'relative',
    paddingLeft: '20px',
    marginLeft: '12px',
    fontSize: '14px',
    maxWidth: '200px',
    textOverflow: 'ellipsis',
    whiteSpace: 'pre',
    overflow: 'hidden',
    '&::after': {
      // content: "''",
      // display: 'block',
      position: 'absolute',
      content: '" "',
      width: '1px',
      height: '26px',
      backgroundColor: '#d9d9d9',
      left: 0,
      top: 0,
    },
    '&:hover': {
      backgroundColor: 'transparent',
    },
    '@media screen and (max-width: 599px)': {
      display: 'none',
    },
  },
  accountNameForNoDrawer: {
    position: 'relative',
    paddingLeft: '20px',
    marginLeft: '12px',
    fontSize: '14px',
    maxWidth: '200px',
    textOverflow: 'ellipsis',
    whiteSpace: 'pre',
    overflow: 'hidden',
    '&::after': {
      content: "''",
      display: 'block',
    },
    '&:hover': {
      backgroundColor: 'transparent',
    },
  },
  avatar: {
    width: '32px',
    height: '32px',
    backgroundColor: 'rgba(0, 157, 225, 0.5)',
    fontSize: '14px',
    lineHeight: '20px',
    marginLeft: '10px',
    marginRight: '4.5px',
  },
  avatarArrow: {
    width: '20px',
    height: '20px',
  },
  // for appbar account end
  // sidebar drawer start
  drawerList: {
    '& .MuiButtonBase-root:hover': {
      backgroundColor: '#3131331A',
    },
  },
  listItemSidebar: {
    padding: '30px 15px',
    '& .MuiListItemButton-root': {
      borderRadius: '6px',
      maxWidth: '220px',
    },
  },
  sidebarSubmenu: {
    marginTop: '2px',
    marginBottom: '10px',
  },
  drawerBox: {
    padding: '62px 0px 0 0px',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between',
    background: '#009DE1',
    height: '100%',
    color: '#FFFFFF',
  },
  // back button start
  backButton: {
    color: '#FFF',
    padding: 0,
    marginBottom: '12px',
    textTransform: 'capitalize',
    minWidth: 'unset',
    fontSize: '14px',
    alignItems: 'center',
    '& .MuiButton-startIcon': {
      width: '20px',
      height: '20px',
      marginLeft: '-5px',
      marginRight: 0,
      marginTop: '2px',
      transform: 'rotateY(180deg)',
      '& path': {
        strokeWidth: '1px',
      },
    },
    '&:hover': {
      backgroundColor: 'transparent !important',
      color: '#ffd514',
      '& .MuiButton-startIcon': {
        '& path': {
          stroke: '#ffd514',
        },
      },
    },
  },
  menuTitle: {
    fontSize: '20px',
    lineHeight: '14px',
    height: '24px',
    textTransform: 'capitalize',
    color: '#8EEDF5',
  },
  // back button end
  // menu list item icon
  listItemIcon: {
    height: '15px',
    width: '20px',
    justifyContent: 'center',
    marginRight: 'auto',
    marginLeft: 'auto',
  },
  // sub menu list item icon
  subMenuIcon: {
    minWidth: 0,
    justifyContent: 'center',
  },
  // list item text
  listItemText: {
    // margin: 0,
    fontSize: '14px',
  },
  // create group button
  createGroupBtn: {
    minWidth: 0,
    justifyContent: 'center',
    alignItems: 'center',
    width: '22px',
    height: '22px',
    backgroundColor: '#FFFFFF33',
    borderRadius: '3px',
    color: 'white',
    padding: '5px',
    '&:hover': { backgroundColor: '#009DE1' },
  },
  // collapse menu icon
  collapseMenuIcon: {
    width: '20px',
    height: '19px',
    // marginTop: '0.5px',
    '& path': {
      strokeWidth: '1px',
    },
  },
  // collapse menu icon when active
  collapseMenuIconActive: {
    '& path': {
      stroke: '#FFD514',
    },
  },
  // arrow expand icon when click on menu item
  arrowExpandIcon: {
    width: '20px',
    height: '20px',
    color: '#fff',
    '& path': {
      stroke: '#fff',
      strokeWidth: '1px',
    },
  },
  // arrow expand icon when click on menu item active
  arrowExpandIconActive: {
    width: '20px',
    height: '20px',
    color: '#fff',
    '& path': {
      stroke: '#313133',
      strokeWidth: '1px',
    },
  },
  // left sidebar bottom drawer toggle button
  drawerBottomToggle: {
    height: '40px',
    backgroundColor: '#FFFFFF1A',
    borderRadius: 0,
    '&:hover': {
      backgroundColor: '#3131331A',
    },
    '& svg': {
      width: '28px',
      height: '28px',
    },
  },
  // device list details start
  transHistoryDialog: {
    padding: '0 40px',
    '& .MuiDialog-container': {
      alignItems: 'start',
    },
    '& .MuiPaper-root': {
      background: mainBackgroundColor,
      minWidth: '100%',
      margin: '53px 0 0 0',
    },
    '& .MuiDialogTitle-root': {
      textAlign: 'right',
    },
    '& .MuiDialogContent-root': {
      borderTop: 'none',
      borderBottom: 'none',
      padding: '0px 24px 24px 24px',
      '& .MuiContainer-root': {
        maxWidth: '100%',
        minWidth: '100%',
      },
    },
  },
  dialogHeader: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
  },
  close: {
    width: '20px',
    height: '20px',
    '& path': {
      stroke: '#939598',
    },
  },
  dialogContentCommon: {
    marginTop: '10px',
  },
  deviceListBtn: {
    minWidth: '157px',
    height: '44px',
    fontSize: '16px',
    fontWeight: 500,
    backgroundColor: '#fff',
    borderRadius: '4px',
    padding: '10px 20px',
    border: '1px solid #009de1',
    color: '#009de1',
    textTransform: 'capitalize',
    marginLeft: '12px',
    letterSpacing: 0,
    '&:hover': {
      backgroundColor: '#fff',
    },
  },
  deviceListBtnWidth: {
    justifyContent: 'space-between',
    minWidth: '234px',
  },
  btnIcon: {
    marginRight: '4px',
    width: '20px',
    height: '20px',
  },
  deviceListBtnActive: {
    border: '1px solid #009de1',
    color: '#009de1',
    '& svg': {
      '& path': {
        fill: '#009de1',
      },
    },
    '&:hover': {
      border: '1px solid #007EB4',
      color: '#007EB4',
      '& svg': {
        '& path': {
          fill: '#007EB4',
        },
      },
    },
  },
  deviceListBtnLost: {
    border: '1px solid #939598',
    backgroundColor: '#fff',
    color: '#939598',
    '& svg': {
      '& path': {
        fill: '#939598',
      },
    },
  },
  deviceListBtnBlack: {
    color: '#fff',
    backgroundColor: '#313133',
    border: 0,
    '& svg': {
      marginRight: '6px',
      '& path': {
        fill: '#fff',
      },
    },
    '&:hover': {
      color: '#fff',
      backgroundColor: '#313133',
      border: 0,
    },
  },
  dialogBox: {
    display: 'flex',
  },
  dialogIcon: {
    minWidth: '72px',
    minHeight: '72px',
    marginRight: '14px',
  },
  dialogCloseIcon: {
    position: 'absolute',
    right: 14,
    top: 14,
  },
  CloseIcon: {
    position: 'absolute',
    right: 24,
    top: 22,
    zIndex: 1,
    color: '#939598',
    cursor: 'pointer',
  },
  dialogSubTitle: {
    fontSize: '14px',
    lineHeight: '22px',
    fontWeight: 300,
    // marginTop: '10px'
  },
  dialogPinBox: {
    display: 'flex',
    alignItems: 'center',
    marginTop: '10px',
    width: 'max-content',
  },
  pinText: {
    fontSize: '14px',
    letterSpacing: '0.09px',
    marginRight: '16px',
  },
  pinInput: {
    // width: '200px',
    // height: '38px',
    borderRadius: '6px',
    paddingRight: '12px',
    '& .MuiOutlinedInput-notchedOutline': {
      borderColor: '#d9d9d9',
    },
    '&:hover': {
      '& .MuiOutlinedInput-notchedOutline': {
        borderColor: '#939598',
      },
    },
    '& .MuiOutlinedInput-input': {
      padding: '0 0 0 10px',
      // fontSize: '14px',
      // letterSpacing: 0,
      '&::-ms-reveal': {
        display: 'none',
      },
      '&::-ms-clear': {
        display: 'none',
      },
      // '-webkit-text-security': 'disc',
      // '-moz-text-security': disc,
      // 'text-security': 'disc',
      // '&::-webkit-contacts-auto-fill-button': {
      //   visibility: 'hidden',
      //   position: 'absolute',
      //   right: 0,
      // },
      // '&::-webkit-credentials-auto-fill-button': {
      //   visibility: 'hidden',
      //   position: 'absolute',
      //   right: 0,
      // }
      '&::-webkit-contacts-auto-fill-button': {
        visibility: 'hidden',
        display: 'none !important',
        'pointer-events': 'none',
        height: 0,
        width: 0,
        margin: 0,
      },
      '&::-webkit-credentials-auto-fill-button': {
        visibility: 'hidden',
        display: 'none !important',
        'pointer-events': 'none',
        height: 0,
        width: 0,
        margin: 0,
      },
    },
    '& .MuiIconButton-root': {
      padding: '0 10px 0 0',
      '&:hover': {
        backgroundColor: 'transparent',
      },
    },
    '& legend': { display: 'none' },
    '& fieldset': { top: 0 },
    '&.MuiOutlinedInput-root.Mui-focused': {
      '& > fieldset': {
        border: '1px solid #939598',
      },
    },
  },
  dialogActionsCommon: {
    marginTop: '20px',
  },
  btnAction: {
    height: '44px',
    borderRadius: '6px',
    padding: '10px 30px',
    border: '1px solid #d9d9d9',
    fontSize: '16px',
    lineHeight: '16px',
    fontWeight: 500,
    color: '#313133',
    textTransform: 'capitalize',
    letterSpacing: 0,
    '&:hover': {
      // border: '1px solid #939598',
      backgroundColor: 'rgba(0, 0, 0, 0.04)',
    },
  },
  btnActionPrimary: {
    border: 'none',
    backgroundColor: '#009DE1',
    color: '#fff',
    '&:hover': {
      border: 'none',
      backgroundColor: '#007EB4',
      color: '#fff',
    },
    '&.Mui-disabled': {
      opacity: '0.5',
      color: '#fff',
    },
  },
  btnActionCommon: {
    color: '#fff',
  },
  // snakebar design start
  snakebar: {
    top: 0,
    maxWidth: '744px',
    height: '52px',
    '& .MuiPaper-root': {
      // backgroundColor: '#4DC176',
      maxWidth: '744px',
      minWidth: '744px',
      height: '52px',
      marginTop: 0,
      marginBottom: 0,
      alignItems: 'center',
      boxShadow: '0px 3px 6px #00000029',
      borderRadius: '3px',
      fontSize: '14px',
      color: '#fff',
      '& .MuiAlert-icon': {
        padding: 0,
      },
      '& .MuiAlert-action': {
        padding: 0,
        '& .MuiIconButton-root': {
          marginRight: 0,
        },
      },
      '@media screen and (max-width: 899px)': {
        maxWidth: '95% !important',
        minWidth: '95% !important',
        height: 'unset',
        minheight: '52px',
      },
    },
    '@media screen and (max-width: 899px)': {
      maxWidth: '95% !important',
      width: '95% !important',
      height: 'unset',
      minHeight: '52px',
    },
  },
  // snakebar design end
  // device list details end

  // group features start
  manageGroupBtn: {
    padding: '9px 19px',
    height: '38px',
    background: '#009DE1',
    textTransform: 'none',
    color: 'white',
    boxShadow: '0',
    borderRadius: '6px',
    '&.Mui-disabled': {
      color: 'white',
      backgroundColor: '#009DE1',
      opacity: 0.5,
    },
    '&:hover': {
      backgroundColor: '#007EB4',
    },
  },
  groupBtnText: {
    fontSize: '14px',
    lineHeight: '14px',
    marginLeft: '8px',
  },
  remoteSettingsBtn: {
    padding: '9px 19px',
    height: '38px',
    color: '#009DE1',
    textTransform: 'none',
    boxShadow: '0',
    borderRadius: '6px',
    border: '0.5px solid rgba(0, 157, 225, .5)',
    marginRight: '8px',
    background: '#FFFFFF',
    '&:hover': {
      color: '#007EB4',
      border: '0.5px solid rgba(0, 126, 180, .5)',
      background: '#FFFFFF',
      '& svg': {
        '& g': {
          '& g': {
            '& line': {
              stroke: '#007EB4',
            },
            '& rect': {
              stroke: '#007EB4',
            },
            '& path': {
              stroke: '#007EB4',
            },
          },
        },
      },
    },
  },
  menuIconButton: {
    marginRight: '8px',
    padding: '0px',
    background: '#FFFFFF',
    border: '0.5px solid rgba(217, 217, 217, .5)',
    borderRadius: '6px',
    boxShadow: '0',
    minWidth: '38px',
    width: '38px',
    height: '38px',
    '&:hover': {
      boxShadow: '0px 3px 6px #00000029',
      background: '#FFFFFF',
    },
    position: 'relative',
  },
  deleteGroupBtn: {
    '&:hover': {
      color: '#E1341E',
      backgroundColor: '#EAEAEB',
      '& svg': {
        '& path': {
          stroke: '#E1341E',
        },
      },
    },
  },
  groupBtnSkeleton: {
    padding: '0px',
    width: '126px',
    height: '38px',
    marginRight: '8px',
  },
  groupBtnIcon: {
    width: '20px',
    height: '20px',
    '& path': {
      fill: '#fff',
    },
  },
  groupBtnIconSkeleton: {
    padding: '0px',
    width: '36px',
    marginRight: '8px',
  },
  groupDetailContainer: {
    paddingRight: '0',
    paddingLeft: '0',
    maxWidth: '100%',
  },
  groupDetailContainerBox: {
    display: 'flex',
    alignItems: 'center',
    marginBottom: '10px',
    marginTop: '-12px',
    height: '38px',
  },
  groupDetailGroupName: {
    maxWidth: '387px',
    fontWeight: 'bold',
    fontSize: '18px',
    whiteSpace: 'pre',
    height: '27px',
    overflow: 'hidden',
    wordBreak: 'inherit',
    marginRight: '13px',
    textOverflow: 'ellipsis',
    '&::after': {
      content: "''",
      display: 'block',
    },
  },
  groupDetailEditIcon: {
    padding: '0px',
    background: '#FFFFFF',
    border: '0.5px solid rgba(0, 157, 225, 0.5)',
    borderRadius: '6px',
    minWidth: '38px',
    width: '38px',
    height: '38px',
    boxShadow: '0',
    '&:hover': {
      border: '0.5px solid rgba(0, 126, 180, 0.5)',
      boxShadow: '0px 3px 6px #00000029',
      background: '#FFFFFF',
      '& svg': {
        '& g': {
          '& #Path_4002': {
            stroke: '#007EB4',
          },
          '& #Path_4003': {
            fill: '#007EB4',
          },
        },
      },
    },
    '&.Mui-disabled': {
      border: '0.5px solid rgba(0, 157, 225, 0.5)',
      opacity: '0.5',
    },
  },
  nameEditSaveIconBtn: {
    margin: '0 8px',
    borderRadius: '6px',
    border: '0.5px solid rgba(0, 157, 225, 0.5)',
    '&:hover': {
      boxShadow: '0px 3px 6px #00000029',
      background: '#FFFFFF',
      border: '0.5px solid rgba(0, 126, 180, 0.5)',
      '& svg': {
        '& g': {
          '& path': {
            fill: '#007EB4',
          },
        },
      },
    },
    '&.Mui-disabled': {
      border: '0.5px solid rgba(0, 157, 225, 0.5)',
      opacity: '0.5',
    },
  },
  groupCreateSaveIcon: {
    marginLeft: '8px',
    borderRadius: '6px',
    border: '0.5px solid rgba(0, 157, 225, 0.5)',
    '&:hover': {
      boxShadow: '0px 3px 6px #00000029',
      background: '#FFFFFF',
      border: '0.5px solid rgba(0, 126, 180, 0.5)',
      '& svg': {
        '& g': {
          fill: '#007EB4',
        },
      },
    },
    '&.Mui-disabled': {
      border: '0.5px solid rgba(0, 157, 225, 0.5)',
      opacity: '0.5',
    },
  },
  closeIconBtn: {
    borderRadius: '6px',
    border: '0.5px solid rgba(0, 157, 225, 0.5)',
    '&:hover': {
      boxShadow: '0px 3px 6px #00000029',
      background: '#FFFFFF',
      border: '0.5px solid rgba(0, 126, 180, 0.5)',
      '& svg': {
        '& g': {
          '& path': {
            stroke: '#007EB4',
          },
        },
      },
    },
    '&.Mui-disabled': {
      border: '0.5px solid rgba(0, 157, 225, 0.5)',
      opacity: '0.5',
    },
  },
  groupNameExistWarningCard: {
    background: '#E1341E',
    color: '#FFFFFF',
    fontSize: '12px',
    boxShadow: '0px 3px 6px #00000029',
    borderRadius: '4px',
    padding: '4px 12px',
    position: 'absolute',
    right: '105px',
    bottom: '46px',
    zIndex: '100',
    fontWeight: 300,
    '&::after': {
      content: '""',
      display: 'block',
      position: 'absolute',
      bottom: '-10px',
      right: '10px',
      width: '12px',
      height: '12px',
      backgroundColor: '#E1341E',
      borderRadius: '1px',
      transform: 'translateY(-50%) rotate(45deg)',
      zIndex: -1,
    },
  },
  groupDeviceListCountText: {
    fontWeight: '700',
    fontSize: '18px',
    lineHeight: '26px',
  },
  noGroupDeviceContainer: {
    minHeight: 'calc(100vh -  378px)',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
  },
  noGroupDeviceIcon: {
    width: '53.55px',
    height: '76.58px',
    marginBottom: '14px',
  },
  noGroupDevicePrimaryText: {
    fontSize: '20px',
    fontWeight: '500',
    lineHeight: '26px',
    textAlign: 'center',
  },
  noGroupDeviceSecondaryText: {
    fontSize: '14px',
    lineHeight: '26px',
    color: '#939598',
    textAlign: 'center',
  },
  // for small device only start
  noGroupDeviceIconSm: {
    '@media screen and (max-width: 375px)': {
      width: '33.55px',
      height: '56.58px',
    },
  },
  noGroupDevicePrimaryTextSm: {
    '@media screen and (max-width: 375px)': {
      fontSize: '14px',
      lineHeight: '20px',
      marginBottom: '10px',
    },
  },
  noGroupDeviceSecondaryTextSm: {
    '@media screen and (max-width: 375px)': {
      fontSize: '10px',
      lineHeight: '16px',
    },
  },
  groupBtnTextSm: {
    '@media screen and (max-width: 375px)': {
      fontSize: '10px',
      lineHeight: '16px',
    },
  },
  // for small device only end

  searchNoDeviceTextPrimary: {
    fontSize: '20px',
    fontWeight: '500',
    lineHeight: '23px',
    marginBottom: '2px',
  },
  searchNoDeviceTextSecondary: {
    fontSize: '14px',
    lineHeight: '23px',
    color: '#939598',
  },
  groupAddBtnCard: {
    display: 'flex',
    justifyContent: 'space-between',
    marginTop: '10px',
  },
  groupAddDataBox: {
    height: `calc(100vh - 310px)`,
    backgroundColor: '#FFFFFF',
    border: '0.5px solid rgba(217, 217, 217, .5)',
    borderRadius: '8px',
    padding: '21px 30px',
    marginTop: '11px',
    '@media screen and (max-height: 640px)': {
      height: 'unset',
    },
  },
  groupInput: {
    '& .MuiInputBase-input': {
      width: '396px',
      height: '38px',
      borderRadius: '6px',
      position: 'relative',
      backgroundColor: '#FFFFFF',
      fontSize: '18px',
      fontWeight: 'bold',
      padding: '0 16px 3px',
      boxSizing: 'border-box',
      color: '#313133',
      opacity: 1,
      transition: theme.transitions.create([
        'border-color',
        'background-color',
        'box-shadow',
      ]),
      '&:-webkit-autofill': {
        WebkitBoxShadow: '0 0 0 100px white inset',
        '-webkit-text-fill-color': '#313133',
      },
      '&::placeholder': {
        color: '#939598',
        fontSize: '14px',
        fontWeight: 'normal',
        opacity: 1,
      },
      fontFamily: ['noto sans jp', 'Noto Sans'].join(','),
      '&:focus': {
        '&::placeholder': { fontSize: '14px', fontWeight: 'normal' },
        fontWeight: 'bold',
        fontSize: '18px',
      },
    },
  },
  paginationRowBtn: {
    border: '1px solid #d9d9d9',
    width: '54px',
    height: '26px',
    backgroundColor: '#fff',
    borderRadius: '4px',
    fontSize: '13px',
    color: '#313133',
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    padding: '6px',
    '&:hover': {
      backgroundColor: 'transparent',
    },
    '& svg': {
      marginTop: '2px',
      width: '9px',
      height: '5px',
    },
  },
  paginationRowMenu: {
    backgroundColor: '#fff',
    boxShadow: '0px 3px 6px #00000029',
    width: '92.5px',
    borderRadius: '6px',
    zIndex: 1300,
    // '&:before': {
    //   content: '""',
    //   position: 'absolute',
    //   right: '12px',
    //   width: '12px',
    //   height: '12px',
    //   backgroundColor: '#fff',
    //   borderRadius: '1px',
    //   transform: 'translateY(-50%) rotate(45deg)',
    //   zIndex: -1,
    // },
    '& .MuiList-root': {
      paddingTop: 0,
      paddingBottom: 0,
      '& .MuiListItem-root': {
        padding: '5px 12.5px',
        fontSize: '13px',
        justifyContent: 'end',
        color: '#313133',
        '&:hover': {
          backgroundColor: '#EAEAEB',
        },
      },
      '& .MuiListItem-root:first-child': {
        borderTopRightRadius: '6px',
        borderTopLeftRadius: '6px',
      },
      '& .MuiListItem-root:not(:last-child)': {
        borderBottom: '0.5px solid rgba(217, 217, 217, .5)',
      },
    },
    '&[data-popper-placement*="top"]': {
      boxShadow: '0px -3px 6px #00000029',
      '&:before': {
        bottom: '-12px',
      },
    },
    '&[data-popper-placement*="bottom"]:before': {
      top: '0',
    },
  },
  customTooltip: {
    width: '60px',
  },

  timePeriodRowBtn: {
    minHeight: '26px',
    backgroundColor: '#F1F1FB',
    borderRadius: '4px',
    fontSize: '13px',
    color: '#313133',
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    padding: '6px',
    paddingLeft: '10px',
    '& p': {
      paddingRight: '9px',
    },
    '&:hover': {
      backgroundColor: '#F1F1FB',
    },
    '& svg': {
      marginTop: '2px',
      width: '9px',
      height: '5px',
    },
  },
  timePeriodRowMenu: {
    backgroundColor: '#fff',
    boxShadow: '0px 3px 6px #00000029',
    width: '140.5px',
    borderRadius: '6px',
    zIndex: 1300,

    '&:before': {
      content: '""',
      position: 'absolute',
      right: '12px',
      width: '12px',
      height: '12px',
      backgroundColor: '#fff',
      borderRadius: '1px',
      transform: 'translateY(-50%) rotate(45deg)',
      zIndex: -1,
    },
    '& .MuiList-root': {
      paddingTop: 0,
      paddingBottom: 0,
      '& .MuiListItem-root': {
        display: 'flex',
        justifyContent: 'space-between',
        '& p': {
          fontSize: '13px',
          justifyContent: 'start',
          color: '#313133',
        },
        padding: '5px 12.5px',

        '&:hover': {
          backgroundColor: '#EAEAEB',
        },
      },
      '& .MuiListItem-root:first-child': {
        borderTopRightRadius: '6px',
        borderTopLeftRadius: '6px',
      },
      '& .MuiListItem-root:not(:last-child)': {
        borderBottom: '0.5px solid rgba(147, 149, 152, 0.5)',
      },
    },
    '&[data-popper-placement*="top"]': {
      boxShadow: '0px 3px 6px #00000029;',
      '&:before': {
        bottom: '-12px',
      },
    },
    '&[data-popper-placement*="bottom"]:before': {
      top: '0',
    },
  },

  // common search input style start
  searchInputFilter: {
    backgroundColor: '#fff',
    width: '396px',
    height: '38px',
    padding: '0 8px 0 16px',
    borderRadius: '6px',
    boxShadow: 'none',
    '& .MuiOutlinedInput-notchedOutline': {
      borderColor: '#d9d9d9',
    },
    '&:hover .MuiOutlinedInput-notchedOutline': {
      borderColor: '#939598',
    },
    '& .MuiInputBase-input': {
      fontSize: '14px',
      lineHeight: '14px',
      padding: '0',
      color: '#313133',
      opacity: 1,
      '&::placeholder': {
        color: '#939598',
        opacity: 1,
      },
    },
    '& legend': { display: 'none' },
    '& fieldset': { top: 0 },
    '&.MuiOutlinedInput-root.Mui-focused': {
      '& > fieldset': {
        border: '1px solid #939598',
      },
    },
  },
  textEllipse: {
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
  },

  // common search input style end
  // add new wifi box style start
  newWifiBox: {
    border: '1px solid #d9d9d9',
    padding: '8px 8px 15px 40px',
    marginBottom: '8px',
    width: '744px',
  },
  newWifiErrorCard: {
    background: '#E1341E',
    color: '#FFFFFF',
    fontSize: '12px',
    boxShadow: '0px 3px 6px #00000029',
    borderRadius: '4px',
    padding: '4px 12px',
    position: 'absolute',
    right: '0px',
    bottom: '41px',
    zIndex: '100',
    fontWeight: 300,
    '&::after': {
      content: '""',
      display: 'block',
      position: 'absolute',
      bottom: '-10px',
      right: '10px',
      width: '12px',
      height: '12px',
      backgroundColor: '#E1341E',
      borderRadius: '1px',
      transform: 'translateY(-50%) rotate(45deg)',
      zIndex: -1,
    },
  },
  newWifiErrorCardPrefixLen: {
    height: '26px',
    background: '#E1341E',
    color: '#FFFFFF',
    fontSize: '12px',
    boxShadow: '0px 3px 6px #00000029',
    borderRadius: '4px',
    padding: '4px 12px',
    position: 'absolute',
    left: '0px',
    bottom: '41px',
    zIndex: '100',
    fontWeight: 300,
    whiteSpace: 'nowrap',
    '&::after': {
      content: '""',
      display: 'block',
      position: 'absolute',
      bottom: '-10px',
      left: '10px',
      width: '12px',
      height: '12px',
      backgroundColor: '#E1341E',
      borderRadius: '1px',
      transform: 'translateY(-50%) rotate(45deg)',
      zIndex: -1,
    },
  },
  // input {
  //   display: block;
  //   box-sizing: border-box;
  //   width: 100%;
  //   border-radius: 4px;
  //   border: 1px solid white;
  //   padding: 10px 15px;
  //   margin-bottom: 10px;
  //   font-size: 14px;
  // }

  customInputLabel: {
    display: 'inline-block',
    // width: '100%',
    letterSpacing: '0.09px',
    fontSize: '14px',
    lineHeight: '19px',
    color: '#313133',
    marginBottom: '6px',
  },
  customInputOutline: {
    width: '100%',
    '& .MuiInputBase-input': {
      boxSizing: 'border-box',
      borderRadius: '3px',
      position: 'relative',
      backgroundColor: '#fff',
      border: '1px solid #d9d9d9',
      fontSize: '14px',
      width: '100%',
      height: '34px',
      padding: '0 10px',
      opacity: 1,
      color: '#313133',
      transition: theme.transitions.create([
        'border-color',
        'background-color',
        'box-shadow',
      ]),
      '&::placeholder': {
        color: '#939598',
        opacity: 1,
      },
      '&:hover': {
        borderColor: '#939598',
      },
      '&:focus': {
        boxShadow: 'none',
        borderColor: '#939598',
      },
      '&:disabled': {
        backgroundColor: '#F8F8F8',
      },
    },
  },
  customRemoteDeviceTextFeldOutline: {
    width: '100%',
    boxSizing: 'border-box',
    borderRadius: '3px',
    position: 'relative',
    border: '1px solid #d9d9d9',
    fontSize: '14px',
    height: '34px',
    padding: '0 10px',
    opacity: 1,
    color: '#313133',
    backgroundColor: '#F8F8F8',
    display: 'flex',
    alignItems: 'center',
  },
  customInputOutlineSmRight: {
    '& .MuiInputBase-input': {
      width: '100px',
      textAlign: 'right',
    },
  },
  customInputOutlineError: {
    '& .MuiInputBase-input': {
      border: '1px solid #E1341E',
      '&:hover': {
        borderColor: '#E1341E',
      },
      '&:focus': {
        borderColor: '#E1341E',
      },
    },
  },
  customInputPinCode: {
    position: 'relative',
    '& .MuiInputBase-input': {
      paddingRight: '35px',
    },
    '& .MuiInputAdornment-root': {
      position: 'absolute',
      right: '12px',
    },
  },

  // for input password start
  passwordInput: {
    width: '100%',
    paddingRight: '10px',
    '& .MuiOutlinedInput-notchedOutline': {
      borderColor: '#d9d9d9',
    },
    '&:hover': {
      '& .MuiOutlinedInput-notchedOutline': {
        borderColor: '#939598',
      },
    },
    '& .MuiOutlinedInput-input': {
      padding: '0 0 0 10px',
      fontSize: '14px',
      '-moz-appearance': 'textfield',
      '&-webkit-outer-spin-button': {
        '-webkit-appearance': 'none',
      },
      '&::-webkit-inner-spin-button': {
        '-webkit-appearance': 'none',
      },
      '&::-ms-reveal': {
        display: 'none',
      },
      '&::-ms-clear': {
        display: 'none',
      },
      '&::-webkit-contacts-auto-fill-button': {
        visibility: 'hidden',
        display: 'none !important',
        'pointer-events': 'none',
        height: 0,
        width: 0,
        margin: 0,
      },
      '&::-webkit-credentials-auto-fill-button': {
        visibility: 'hidden',
        display: 'none !important',
        'pointer-events': 'none',
        height: 0,
        width: 0,
        margin: 0,
      },
      // '&:-webkit-autofill': {
      //   WebkitBoxShadow: '0 0 0 100px white inset',
      //   '-webkit-text-fill-color': '#313133',
      // },
    },
    '& .MuiIconButton-root': {
      padding: '0 10px 0 0',
      '&:hover': {
        backgroundColor: 'transparent',
      },
    },
    '& legend': { display: 'none' },
    '& fieldset': { top: 0 },
    '&.MuiOutlinedInput-root.Mui-focused': {
      '& > fieldset': {
        border: '1px solid #939598',
      },
    },
  },
  passwordInputMd: {
    height: '38px',
    borderRadius: '6px',
    '& .MuiIconButton-root': {
      '& svg': {
        width: '20px',
        height: '20px',
      },
    },
  },
  passwordInputSm: {
    height: '34px',
    borderRadius: '3px',
    '& .MuiIconButton-root': {
      '& svg': {
        width: '16px',
        height: '16px',
      },
    },
  },
  passwordInputTypo: {
    '& .MuiOutlinedInput-input': {
      fontSize: '14px',
      letterSpacing: 0,
    },
  },
  errorInput: {
    '& .MuiOutlinedInput-notchedOutline': {
      borderColor: '#E1341E',
    },
    '&.MuiOutlinedInput-root.Mui-focused': {
      '& > fieldset': {
        border: '1px solid #E1341E',
      },
    },
    '&:hover': {
      '& .MuiOutlinedInput-notchedOutline': {
        borderColor: '#E1341E',
      },
    },
  },
  // for input password end
  customSelectButton: {
    width: '100%',
    height: '34px',
    border: '1px solid #d9d9d9',
    padding: '0 10px',
    opacity: 1,
    color: '#313133',
    borderRadius: '3px',
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    textAlign: 'left',
    fontSize: '14px',
    '& .MuiTypography-root': {
      fontSize: '14px',
    },
    '&:hover': {
      border: '1px solid #939598',
    },
    '&:focus': {
      border: '1px solid #939598',
    },
  },
  selectArrow: {
    marginTop: '2px',
    width: '20px',
    height: '20.6px',
    '& > g > path': {
      strokeWidth: 1.5,
    },
  },
  selectDropdownMenu: {
    backgroundColor: '#fff',
    boxShadow: '0px 3px 20px #00000029',
    width: '200px',
    border: '1px solid #D9D9D9',
    borderRadius: '6px',
    zIndex: 1300,
    '&:before': {
      content: '""',
      position: 'absolute',
      right: '12px',
      width: '12px',
      height: '12px',
      backgroundColor: '#fff',
      // borderRadius: '1px',
      // transform: 'translateY(-50%) rotate(45deg)',
      zIndex: -1,
    },
    '& .MuiList-root': {
      paddingTop: 0,
      paddingBottom: 0,
      '& .MuiListItem-root': {
        padding: '0 10px',
        width: '200px',
        height: '35px',
        fontSize: '13px',
        justifyContent: 'space-between',
        alignItems: 'center',
        '&:hover': {
          backgroundColor: '#f8f8f8',
          color: '#313131',
        },
      },
      '& .MuiListItem-root:first-child': {
        borderTopRightRadius: '6px',
        borderTopLeftRadius: '6px',
      },
      '& .MuiListItem-root:not(:last-child)': {
        borderBottom: '0.5px solid rgba(217, 217, 217, .5)',
      },
      '& .MuiListItem-root:last-child': {
        borderBottomRightRadius: '6px',
        borderBottomLeftRadius: '6px',
      },
      '& .MuiListItemSecondaryAction-root': {
        right: '10px',
      },
    },
    '&[data-popper-placement*="top"]': {
      boxShadow: '0px -3px 6px #00000029',
      '&:before': {
        bottom: '-12px',
      },
    },
    '&[data-popper-placement*="bottom"]:before': {
      top: '0',
    },
  },
  advancedOptionsBtn: {
    padding: 0,
    fontSize: '12px',
    height: '16px',
    color: '#313133',
    opacity: 1,
    marginTop: '13px',
    textTransform: 'none',
    '& .MuiTypography-root': {
      fontSize: '12px',
    },
  },
  remoteDialogInnerWrapper: {
    maxWidth: '660px',
    paddingTop: '12px',
  },
  gridCol3: {
    display: 'grid',
    gridTemplateColumns: 'repeat(3, 1fr)',
    gap: '30px',
  },
  addIcon: {
    width: '9px',
    height: '9px',
    '& svg': {
      width: '9px',
      height: '9px',
    },
  },
  wifiBoxWrapper: {
    // minHeight: '286px',
    '@media screen and (max-height:820px)': {
      minHeight: `calc(${remotDeviceSetupDialogHeight} - 450px)`,
    },
  },
  noWifiBox: {
    margin: '16px 0px 20px',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    flexDirection: 'column',
    width: '744px',
    height: '126px',
    border: '1px solid #D9D9D9',
    // '@media screen and (max-height:800px)': {
    //   height: `calc(${remotDeviceSetupDialogHeight} - 450px)`,
    // },
    '@media screen and (max-height: 640px)': {
      height: '150px',
    },
  },
  tooltipIcon: {
    width: '16px',
    height: '16px',
    '& svg': {
      width: '16px',
      height: '16px',
    },
  },
  // add new wifi box style end
  // customize react select option list start
  reactSelect: {
    '& .react-select__menu': {
      margin: '1px',
      '& .react-select__menu-list': {
        paddingTop: 0,
        paddingBottom: 0,
        '& .react-select__option': {
          padding: '8.5px 10px',
          width: '100%',
          // height: '35px',
          fontSize: '13px',
          lineHeight: '14px',
          display: 'flex',
          justifyContent: 'space-between',
          alignItems: 'center',
          cursor: 'pointer',
          '&:hover': {
            backgroundColor: '#EAEAEB',
            color: '#313131',
          },
        },
        // '& .react-select__option:first-child': {
        //   borderTopRightRadius: '6px',
        //   borderTopLeftRadius: '6px',
        // },
        '& .react-select__option:not(:last-child)': {
          borderBottom: '0.5px solid rgba(217, 217, 217, .5)',
        },
        // '& .react-select__option:last-child': {
        //   borderBottomRightRadius: '6px',
        //   borderBottomLeftRadius: '6px',
        // },
      },
    },
  },
  deleteIcon: {
    cursor: 'pointer',
    width: '24px',
    height: '24px',
    padding: '1.5px',
    '&:hover': {
      '& path': {
        stroke: '#E1341E',
      },
    },
  },
  // customize react select option list end
  // table card inner elements style start
  tableCardInner: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    height: 'calc(100vh -  336px)',
    '@media screen and (max-height: 800px)': {
      height: 'calc(100vh -  325px)',
    },
  },
  tableCardInnerContent: {
    display: 'flex',
    flexGrow: 1,
    alignItems: 'center',
    justifyContent: 'center',
    textAlign: 'center',
    paddingTop: '30px',
  },
  disableSearch: {
    backgroundColor: '#F8F8F8',
    '& .MuiOutlinedInput-notchedOutline': {
      borderColor: '#d9d9d9',
    },
    '&:hover .MuiOutlinedInput-notchedOutline': {
      borderColor: '#d9d9d9',
    },
    '&.MuiOutlinedInput-root.Mui-disabled': {
      '& > fieldset': {
        border: '1px solid #d9d9d9',
      },
      '& .MuiButtonBase-root': {
        '&:hover': {
          backgroundColor: 'unset',
          'pointer-events': 'none',
        },
        cursor: 'auto',
      },
      '& .MuiOutlinedInput-input.Mui-disabled': {
        '-webkit-text-fill-color': '#939598',
        '&::placeholder': {
          color: '#939598',
        },
      },
    },
    '&.MuiOutlinedInput-root.Mui-focused': {
      '& > fieldset': {
        border: '1px solid #d9d9d9',
      },
    },
  },
  // table card inner elements style end
  breadcrumbLink: {
    fontSize: '14px',
    lineHeight: '20px',
    cursor: 'pointer',
    textDecoration: 'none',
    color: '#009DE1',
    '&:hover': {
      color: '#007EB4',
    },
  },
  // LogIn Form
  loginCard: {
    width: '560px',
    // height: '369px',
    background: '#FFFFFF',
    border: '0.5px solid rgba(217, 217, 217, .5)',
    // boxShadow: 'inset 0 0 0 0.5px #D9D9D9',
    borderRadius: '12px',
    padding: '31px',
  },
  registrationCard: {
    width: '560px',
    // height: '558px',
    background: '#FFFFFF 0% 0% no-repeat padding-box',
    borderRadius: '12px',
    padding: '32px',
  },
  loginCardTitle: {
    fontSize: '20px',
    lineHeight: '29px',
    fontWeight: 'bold',
    color: '#313133',
    marginBottom: '20px',
    '@media screen and (max-width: 400px)': {
      fontSize: '18px',
    },
  },
  registrationCardTitle: {
    fontSize: '20px',
    lineHeight: '29px',
    fontWeight: 'bold',
    color: '#313133',
    marginBottom: '16px',
    '@media screen and (max-width: 400px)': {
      fontSize: '18px',
    },
  },
  loginCardLevel: {
    fontSize: '14px',
    height: '20px',
    color: '#313133',
    '@media screen and (max-width: 400px)': {
      textAlign: 'center',
    },
  },
  registrationCardLevel: {
    fontSize: '14px',
    height: '20px',
    color: '#313133',
  },
  loginCardLevelMarker: {
    fontSize: '14px',
    color: '#E1341E',
    marginLeft: '3px',
  },

  registrationCardLink: {
    textDecoration: 'none',
    color: '#009DE1',
    paddingRight: '2px',
    '&:hover': {
      color: '#007EB4',
    },
  },
  weakPasswordStrengthWarn: {
    marginLeft: 'auto',
    fontSize: '12px',
    color: '#E1341E',
  },
  goodPasswordStrengthWarn: {
    marginLeft: 'auto',
    fontSize: '12px',
    color: '#ffad00',
  },
  strongPasswordStrengthWarn: {
    marginLeft: 'auto',
    fontSize: '12px',
    color: '#00A73B',
  },
  loginFormInput: {
    marginTop: '6px',
    '& .MuiOutlinedInput-input': {
      backgroundColor: '#fff',
      padding: '8px 16px 10px 16px',
      height: '20px',
      fontSize: '14px',
      '&::placeholder': {
        color: '#939598',
        opacity: 1,
      },
    },
    '& legend': { display: 'none' },
    '& fieldset': { top: 0 },
    '& .MuiOutlinedInput-notchedOutline': {
      borderColor: '#D9D9D9',
    },
  },
  forgotPassword: {
    textAlign: 'left',

    '& button': {
      color: '#009DE1',
      textAlign: 'left',
      border: 'none',
      fontSize: '14px',
      padding: '0px',
      height: '20px',
      background: 'transparent',

      '&:hover': {
        color: '#007EB4',
        // textDecoration: 'underline',
      },
    },
  },
  submitButtonLogin: {
    marginTop: '20px',
    backgroundColor: '#009DE1',
    height: '44px',
    fontWeight: '500',
    fontSize: '16px',
    padding: '10px 22px',
    color: '#fff',
    position: 'relative',
    textTransform: 'none',
    borderRadius: '6px',
    '&:hover': {
      backgroundColor: '#007EB4',
    },
    '&.Mui-disabled': {
      color: 'white',
      background: '#009DE1',
      opacity: 0.5,
    },
  },
  errorCard: {
    background: '#E1341E',
    color: '#FFFFFF',
    fontSize: '12px',
    boxShadow: '0px 3px 6px #00000029',
    borderRadius: '4px',
    padding: '4px 12px',
    position: 'absolute',
    right: '0px',
    bottom: '53px',
    zIndex: '100',
    fontWeight: 300,
    '&::after': {
      content: '""',
      display: 'block',
      position: 'absolute',
      bottom: '-10px',
      right: '10px',
      width: '12px',
      height: '12px',
      backgroundColor: '#E1341E',
      borderRadius: '1px',
      transform: 'translateY(-50%) rotate(45deg)',
      zIndex: -1,
    },
  },
  selectGroupModel: {
    border: '1px solid #D9D9D9',
    borderRadius: '6px',
    boxShadow: '0px 3px 20px #00000029',
    top: '45px',
    zIndex: 1300,
  },
  selectGroupPopperBox: {
    maxHeight: '226px',
    background: '#FFFFFF 0% 0% no-repeat padding-box',
    boxShadow: '0px 3px 20px #00000029',
    border: '1px solid #D9D9D9',
    borderRadius: '6px',
    opacity: '1',
  },
  selectGroupPopperBoxScrollBar: {
    maxHeight: '180px',
    '&:last-child': {
      borderRadius: '0px 0px 6px 6px',
    },
  },
  // End LogIn Form
  // Star Device Details page
  detailsCard: {
    width: '780px',
    minHeight: '290px',
    backgroundColor: '#FFFFFF',
    border: '0.5px solid rgba(217, 217, 217, .5)',
    borderRadius: '8px',
    padding: '24px 32px',
    display: 'flex',
    marginRight: '20px',
    '@media screen and (max-width:1660px)': {
      width: '55%',
      padding: '24px 20px',
      marginRight: '18px',
    },
    '@media screen and (max-width:1440px)': {
      width: '55%',
      padding: '24px 15px',
      marginRight: '15px',
    },
    '@media screen and (max-width:1360px)': {
      width: '55%',
      padding: '24px 15px',
      marginRight: '10px',
    },
    '@media screen and (max-width:1300px)': {
      width: '55%',
      padding: '24px 15px',
      marginRight: '10px',
      justifyContent: ' space-between',
    },
  },
  deviceDetailsLeftCard: {
    width: '198px',
    marginRight: '40px',
    '@media screen and (max-width:1660px)': {
      width: '140px',
      marginRight: '15px',
    },
    '@media screen and (max-width:1440px)': {
      width: '140px',
      marginRight: '10px',
    },
    '@media screen and (max-width:1360px)': {
      width: '135px',
      marginRight: '10px',
    },
    '@media screen and (max-width:1300px)': {
      width: '135px',
      marginRight: '0px',
    },
  },
  deviceDetailsLeftCardFirstSec: {
    width: '251px',
    marginRight: '40px',
    '@media screen and (max-width:1660px)': {
      marginRight: '30px',
    },
    '@media screen and (max-width:1440px)': {
      marginRight: '15px',
    },
    '@media screen and (max-width:1360px)': {
      marginRight: '10px',
    },
    '@media screen and (max-width:1300px)': {
      width: '211px',
      marginRight: '5px',
    },
  },
  deviceDetailsDeviceName: {
    fontSize: '14px',
    color: '#313133',
    maxWidth: '180px',
    '@media screen and (max-width:1300px)': {
      maxWidth: '160px',
    },
    whiteSpace: 'pre',
    textOverflow: 'ellipsis',
    '&::after': {
      content: "''",
      display: 'block',
    },
  },
  deviceDetailsGroupName: {
    fontSize: '14px',
    color: '#313133',
    maxWidth: '200px',
    '@media screen and (max-width:1500px)': {
      maxWidth: '180px',
    },
    '@media screen and (max-width:1400px)': {
      maxWidth: '160px',
    },
    '@media screen and (max-width:1360px)': {
      maxWidth: '140px',
    },
    '@media screen and (max-width:1320px)': {
      maxWidth: '120px',
    },
    '@media screen and (max-width:1260px)': {
      maxWidth: '97px',
    },
    whiteSpace: 'pre',
    textOverflow: 'ellipsis',
    '&::after': {
      content: "''",
      display: 'block',
    },
  },
  itemName: {
    fontSize: '14px',
    fontWeight: 'bold',
    // height: '20px',
    color: '#313133',
    marginBottom: '5px',
  },
  itemData: {
    fontSize: '14px',
    // height: '20px',
    color: '#313133',
    whiteSpace: 'pre-wrap',
  },
  deviceNameEditButton: {
    padding: '0px',
    background: '#FFFFFF',
    border: '0.5px solid rgba(0, 157, 225, 0.5)',
    borderRadius: '6px',
    minWidth: 'unset',
    width: '30px',
    height: '30px',
    boxShadow: '0',
    '&:hover': {
      border: '0.5px solid rgba(0, 126, 180, 0.5)',
      boxShadow: '0px 3px 6px #00000029',
      background: '#FFFFFF',
      '& svg': {
        '& g': {
          '& #Path_4002': {
            stroke: '#007EB4',
          },
          '& #Path_4003': {
            fill: '#007EB4',
          },
        },
      },
    },
    '&.Mui-disabled': {
      border: '0.5px solid rgba(0, 157, 225, 0.5)',
      opacity: '0.5',
      background: '#FFFFFF',
    },
  },
  editInput: {
    margin: '0px',
    minWidth: '180px',
    '@media screen and (max-width:1300px)': {
      minWidth: '140px',
    },
    '& .MuiFormControl-root-MuiTextField-root': {
      minWidth: '180px',
      '@media screen and (max-width:1300px)': {
        minWidth: '140px',
      },
    },
    '& .MuiFormControl-root': {
      minWidth: '180px',
      '@media screen and (max-width:1300px)': {
        minWidth: '140px',
      },
    },
    '& .MuiInputBase-root': {
      width: '180px',
      '@media screen and (max-width:1300px)': {
        width: '140px',
      },
      height: '30px',
    },
    '& .MuiOutlinedInput-input': {
      backgroundColor: '#fff',
      padding: '5px 10px',
      fontSize: '14px',
      '&::placeholder': {
        color: '#939598',
        fontSize: '12.5px',
        opacity: 1,
        '@media screen and (max-width:1300px)': {
          fontSize: '9.5px',
        },
      },
    },
    '& legend': { display: 'none' },
    '& fieldset': { top: 0 },
    '& .MuiOutlinedInput-notchedOutline': {
      border: '0.5px solid  #939598',
    },
  },
  deviceDetailsIconBtn: {
    width: '30px',
    height: '30px',
    padding: '6px',
    margin: '0px !important',
  },
  deviceNameError: {
    minHeight: '26px',
    background: '#E1341E',
    color: '#FFFFFF',
    fontSize: '12px',
    boxShadow: '0px 3px 6px #00000029',
    borderRadius: '4px',
    padding: '4px 11px',
    position: 'absolute',
    right: '64px',
    bottom: '37px',
    zIndex: '100',
    '&::after': {
      content: '""',
      display: 'block',
      position: 'absolute',
      bottom: '-10px',
      right: '10px',
      width: '12px',
      height: '12px',
      backgroundColor: '#E1341E',
      borderRadius: '1px',
      transform: 'translateY(-50%) rotate(45deg)',
      zIndex: -1,
    },
  },
  headerCompanyTooltipStyle: {
    maxWidth: '210px',
    padding: '4px 14px',
    fontWeight: 300,
    fontSize: '12px',
    textAlign: 'center',
    lineHeight: '17px',
    whiteSpace: 'pre-wrap',
  },
  headerAccountTooltipStyle: {
    // padding: '4px 14px',
    fontWeight: 300,
    fontSize: '12px',
    marginRight: '13px',
    maxWidth: '150px',
    textAlign: 'center',
  },
  iconTooltipStyle: {
    padding: '4px 14px',
    fontWeight: 300,
    fontSize: '12px',
  },
  tooltipStyle: {
    maxWidth: '240px',
    padding: '8px 15px 9px',
    textAlign: 'center',
    fontWeight: 300,
    fontSize: '12px',
    backgroundColor: '#313133',
    lineHeight: '17px',
    whiteSpace: 'pre-wrap',
  },
  StatusDetailsCard: {
    position: 'relative',
    width: '567px',
    minHeight: '290px',
    backgroundColor: '#FFFFFF',
    border: '0.5px solid rgba(217, 217, 217, .5)',
    borderRadius: '8px',
    padding: '24px 32px',
    display: 'flex',
    '@media screen and (max-width:1660px)': {
      width: '45%',
      maxWidth: '567px',
    },
    '@media screen and (max-width:1550px)': {
      padding: '24px 20px',
      justifyContent: 'space-between',
      gap: '5px',
    },
    '@media screen and (max-width:1330px)': {
      padding: '24px 10px',
      justifyContent: 'space-between',
      gap: '5px',
    },
  },
  itemDataLight: {
    fontSize: '12px',
    height: '17px',
    color: '#B9B9B9',
    marginTop: '3px',
  },
  itemDataOnOff: {
    fontSize: '14px',
    height: '20px',
    color: '#939598',
    marginTop: '-4px',
    marginRight: '12px',
  },
  pinCodeError: {
    background: '#E1341E',
    color: '#FFFFFF',
    fontSize: '12px',
    boxShadow: '0px 3px 6px #00000029',
    borderRadius: '4px',
    padding: '4px 12px',
    position: 'absolute',
    right: '177px',
    top: '110px',
    zIndex: '100',
    fontWeight: 300,
    '&::after': {
      content: '""',
      display: 'block',
      position: 'absolute',
      bottom: '-10px',
      right: '10px',
      width: '12px',
      height: '12px',
      backgroundColor: '#E1341E',
      borderRadius: '1px',
      transform: 'translateY(-50%) rotate(45deg)',
      zIndex: -1,
    },
  },
  transHistorySearchCard: {
    width: '100%',
    display: 'flex',
    justifyContent: 'space-between',
  },
  transHistoryFilterCard: {
    display: 'flex',
    alignItems: 'center',
  },
  historyFormText: {
    fontSize: '13px',
    fontWeight: 500,
    paddingRight: '12px',
    color: '#313133',
    marginLeft: '5px',
  },
  dateRangeBox: {
    position: 'relative',
    width: '228px',
    backgroundColor: '#ffffff',
    borderRadius: '6px',
    cursor: 'pointer',
    height: '38px',
    '&:hover': {
      border: '1px solid #939598',
    },
  },
  calenderLogo: {
    position: 'absolute',
    right: '10px',
    top: '9px',
    zIndex: '0',
  },
  exportDataBtn: {
    marginLeft: '20px',
    padding: '9px 15px',
    height: '38px',
    background: '#009DE1',
    borderRadius: '6px',
    textTransform: 'none',
    '&:hover': {
      backgroundColor: '#007EB4',
    },
    '&.Mui-disabled': {
      color: 'white',
      background: '#009DE1',
      opacity: 0.5,
    },
  },
  exportDataText: {
    fontSize: '14px',
    color: '#FFFFFF',
    marginLeft: '6px',
    lineHeight: '14px',
  },
  transHistoryCard: {
    height: 'calc(100vh -  597px)',
    minHeight: '250px',
    backgroundColor: '#FFFFFF',
    border: '0.5px solid rgba(217, 217, 217, .5)',
    borderRadius: '8px',
    padding: '20px 30px',
    marginTop: '12px',
  },
  tableTransHistoryCard: {
    minHeight: 'calc(100vh -  594px)',
    '@media screen and (max-width:1440px)': {
      minHeight: '295px',
    },
    backgroundColor: '#FFFFFF',
    border: '0.5px solid rgba(217, 217, 217, .5)',
    borderRadius: '8px',
    padding: '20px 30px 30px',
    marginTop: '12px',
  },
  tableTransHistoryTitleText: {
    fontWeight: 'bold',
    fontSize: '18px',
    lineHeight: '26px',
  },
  tableTransHistoryNoDataTextOne: {
    fontSize: '16px',
    color: '#939598',
    marginTop: '2px',
    lineHeight: '26px',
  },
  tableTransHistorySearchText: {
    fontSize: '14px',
    lineHeight: '26px',
    color: '#939598',
  },
  // End Device Details page
  userAddDialog: {
    '& .MuiDialog-paper': {
      top: '-9%',
      overflow: 'visible',
      borderRadius: '12px',
      maxWidth: '460px',
      minWidth: '460px',
      minHeight: '469px',
    },
  },
  userAddDialogDropD: {
    '& .MuiDialog-paper': {
      borderRadius: '12px',
      maxWidth: '460px',
      minWidth: '460px',
      maxHeight: '500px',
      minHeight: '500px',
    },
  },
  userAddField: {
    marginTop: '0px !important',
    marginBottom: '12px',
    width: '396px',
    '& .MuiOutlinedInput-input': {
      backgroundColor: '#ffffff',
      padding: '8px 16px 10px 16px',
      height: '20px',
      fontSize: '14px',
      '&::placeholder': {
        color: '#939598',
        opacity: 1,
      },
    },
    '& legend': { display: 'none' },
    '& fieldset': { top: 0 },
    '& .MuiOutlinedInput-notchedOutline': {
      borderColor: '#939598',
      borderRadius: '6px',
    },
  },

  // Personal Setting
  userNameError: {
    minHeight: '26px',
    background: '#E1341E',
    color: '#FFFFFF',
    fontSize: '12px',
    boxShadow: '0px 3px 6px #00000029',
    borderRadius: '4px',
    padding: '4px 11px',
    position: 'absolute',
    right: '3px',
    bottom: '53px',
    zIndex: '100',
    '&::after': {
      content: '""',
      display: 'block',
      position: 'absolute',
      bottom: '-10px',
      right: '10px',
      width: '12px',
      height: '12px',
      backgroundColor: '#E1341E',
      borderRadius: '1px',
      transform: 'translateY(-50%) rotate(45deg)',
      zIndex: -1,
    },
  },
  // End Personal Setting
  // start Email Send Card
  emailSendCard: {
    marginTop: '40px',
    background: '#FFFFFF',
    width: '560px',
    // height: '265px',
    padding: '32px',
    borderRadius: '12px',
  },
  emailSendCardTitle: {
    fontSize: '20px',
    fontWeight: 'bold',
    lineHeight: '29px',
    '@media screen and (max-width: 400px)': {
      fontSize: '18px',
    },
  },
  linkBtnBox: {
    display: 'flex',
    gap: '4px',
    justifyContent: 'center',
    marginTop: '8px',
    '@media screen and (max-width: 400px)': {
      flexWrap: 'wrap',
      flexDirection: 'column',
    },
  },
  btnLink: {
    color: '#009DE1',
    fontSize: '14px',
    fontWeight: '400',
    padding: '0px',
    height: '20px',
    textTransform: 'none',
    '&:hover': {
      color: '#007EB4',
    },
  },
  // end Email Send Card

  emailSendSuccessCard: {
    marginTop: '40px',
    background: '#FFFFFF',
    width: '560px',
    // height: '253px',
    padding: '32px',
    borderRadius: '12px',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  },
  emailSendSuccessCardText: {
    fontSize: '14px',
    fontWeight: '400',
    textAlign: 'center',
  },
  emailSendSuccessCardEmail: {
    display: 'inline-block',
    maxWidth: '200px',
    textOverflow: 'ellipsis',
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    marginBottom: '-5px',
    textAlign: 'center',
    '&::after': {
      content: "''",
      display: 'block',
    },
  },

  // Expire link Card
  expireLinkCard: {
    marginTop: '40px',
    background: '#FFFFFF',
    border: '1px solid #EAEDED',
    width: '560px',
    padding: '32px',
    borderRadius: '12px',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  },

  // password set up card
  passwordSetUpCard: {
    marginTop: '40px',
    background: '#FFFFFF',
    border: '0.5px solid rgba(217, 217, 217, .5)',
    width: '560px',
    minHeight: '365px',
    padding: '31px',
    borderRadius: '12px',
  },
  passwordSetUpTitle: {
    fontSize: '20px',
    fontWeight: 'bold',
    height: '29px',
    marginBottom: '16px',
  },
  // end password set up card
  // password set success card
  passwordSetSuccessCard: {
    marginTop: '40px',
    background: '#FFFFFF',
    width: '560px',
    minHeight: '232px',
    padding: '32px',
    borderRadius: '12px',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  },
  // end password set success card

  // star personal console setting Card
  personalConsoleSettingCard: {
    width: '100%',
    background: '#FFFFFF',
    border: '0.5px solid rgba(217, 217, 217, .5)',
    borderRadius: '8px',
    minHeight: '184px',
    // maxHeight: '409px',
    marginTop: '20px',
    padding: '32px',
  },
  personalConsoleSettingTitleCard: {
    display: 'flex',
    alignItems: 'center',
    marginBottom: '26px',
  },
  personalConsoleSettingCardTitle: {
    fontSize: '20px',
    fontWeight: '500',
    lineHeight: '20px',
    marginLeft: '8px',
  },
  personalConsoleSettingRoleCard: {
    display: 'flex',
    alignItems: 'center',
    // marginBottom: '14px',
  },
  personalConsoleSettingRoleText: {
    fontSize: '14px',
    lineHeight: '20px',
    fontWeight: '500',
    // marginRight: '138px',
  },
  personalConsoleSettingGroupTitle: {
    fontSize: '14px',
    lineHeight: '20px',
    fontWeight: '500',
    // marginRight: '119px',
  },
  personalConsoleSettingCardGroupBox: {
    display: 'flex',
    gap: '12px',
    columnGap: '6px',
    flexWrap: 'wrap',
    alignContent: 'flex-start',
    // marginRight: '20px',
  },
  personalConsoleSettingCardGroupNameBox: {
    height: '25px',
    padding: '3px 10px 5px 10px',
    border: '0.5px solid rgba(217, 217, 217, .5)',
    borderRadius: '4px',
    background: '#F8F8F8',
  },
  consoleSettingCardSingleGroupName: {
    fontSize: '12px',
    width: 'fit-content',
    maxWidth: '142px',
    whiteSpace: 'pre',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    height: '18px',
    '&::after': {
      content: "''",
      display: 'block',
    },
  },
  // end personal console setting Card
  // start user detail page
  userDeatilBtnCard: {
    display: 'flex',
    justifyContent: 'flex-end',
    margin: '5px 0px 12px',
  },
  userDeatilSaveBtn: {
    padding: '9px 22px 9px 15px',
    background: '#009DE1',
    textTransform: 'none',
    color: 'white',
    boxShadow: '0',
    borderRadius: '6px',
    '&:hover': {
      backgroundColor: '#007EB4',
    },
    '&.Mui-disabled': {
      color: 'white',
      background: '#009DE1',
      opacity: 0.5,
    },
  },
  userDeatilSaveText: {
    fontSize: '14px',
    lineHeight: '14px',
    marginLeft: '6px',
  },
  userDeatilIconBtn: {
    marginLeft: '10px',
    padding: '0px',
    background: '#FFFFFF',
    border: '0.5px solid rgba(197, 197, 197, .5)',
    borderRadius: '6px',
    boxShadow: '0',
    minWidth: 'unset',
    width: '38px',
    height: '38px',
    '&:hover': {
      boxShadow: '0px 3px 6px #00000029',
      background: '#FFFFFF',
    },
    position: 'relative',
  },
  userDeatilDataCard: {
    width: '100%',
    background: '#FFFFFF',
    border: '0.5px solid rgba(217, 217, 217, .5)',
    borderRadius: '8px',
    minHeight: '356px',
    padding: '32px',
    '@media screen and (max-width:1530px)': {
      padding: '32px 20px',
    },
  },
  userDeatilDataCardInfoBox: {
    display: 'flex',
    justifyContent: 'space-between',
    marginBottom: '24px',
    gap: '5px',
  },
  userDeatilDataCardInfoBoxTwo: {
    display: 'flex',
    gap: '32px',
    alignItems: 'center',
    '@media screen and (max-width:1360px)': {
      gap: '20px',
    },
  },
  userDeatilDataCardInfoName: {
    fontSize: '28px',
    lineHeight: '40px',
    fontWeight: '500',
    maxWidth: '300px',
    '@media screen and (max-width:1730px)': {
      maxWidth: '250px',
    },
    '@media screen and (max-width:1630px)': {
      maxWidth: '200px',
    },
    '@media screen and (max-width:1530px)': {
      maxWidth: '180px',
    },
    '@media screen and (max-width:1420px)': {
      maxWidth: '200px',
    },
    '@media screen and (max-width:1280px)': {
      maxWidth: '170px',
    },
    '@media screen and (max-width:1199px)': {
      maxWidth: '400px',
    },
    '@media screen and (max-width:970px)': {
      maxWidth: '210px',
    },
    '@media screen and (max-width:400px)': {
      maxWidth: '170px',
    },
    // whiteSpace: 'nowrap',
    whiteSpace: 'pre',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    '&::after': {
      content: "''",
      display: 'block',
    },
  },
  userDeatilDataCardInfoRole: {
    fontSize: '15px',
    lineHeight: '24px',
    marginBottom: '4px',
    color: '#939598',
    '@media screen and (max-width: 599px)': {
      textAlign: 'center',
    },
  },
  userDeatilDataCardInfoEmail: {
    fontSize: '15px',
    color: '#939598',
    maxWidth: '300px',
    '@media screen and (max-width:1730px)': {
      maxWidth: '250px',
    },
    '@media screen and (max-width:1630px)': {
      maxWidth: '200px',
    },
    '@media screen and (max-width:1530px)': {
      maxWidth: '180px',
    },
    '@media screen and (max-width:1420px)': {
      maxWidth: '200px',
    },
    '@media screen and (max-width:1280px)': {
      maxWidth: '170px',
    },
    '@media screen and (max-width:1199px)': {
      maxWidth: '400px',
    },
    '@media screen and (max-width:970px)': {
      maxWidth: '210px',
    },
    '@media screen and (max-width:400px)': {
      maxWidth: '170px',
    },
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    '&::after': {
      content: "''",
      display: 'block',
    },
  },
  userDeatilLastActiveBox: {
    display: 'flex',
    alignItems: 'flex-end',
    marginBottom: '15px',
    '@media screen and (max-width:1420px)': {
      flexDirection: 'column',
      justifyContent: 'flex-end',
      marginBottom: '20px',
    },
  },
  userDeatilLastActiveText: {
    fontSize: '15px',
    color: '#939598',
    marginRight: '4px',
    '@media screen and (max-width:1420px)': {
      marginRight: '0px',
    },
  },
  userDeatilConsoleSettingCard: {
    width: '100%',
    background: '#FFFFFF',
    border: '0.5px solid rgba(217, 217, 217, .5)',
    borderRadius: '8px',
    height: '344px',
    marginTop: '20px',
    padding: '32px',
  },
  userDeatilConsoleSettingTitleBox: {
    display: 'flex',
    alignItems: 'center',
    marginBottom: '26px',
  },
  userDeatilConsoleSettingTitleText: {
    fontSize: '20px',
    fontWeight: '500',
    lineHeight: '20px',
    marginLeft: '8px',
  },
  userDeatilConsoleSettingRoleText: {
    fontSize: '14px',
    lineHeight: '20px',
    marginBottom: '6px',
  },
  userDeatilConsoleSettingGroupText: {
    fontSize: '14px',
    lineHeight: '20px',
    marginTop: '9px',
    marginBottom: '6px',
  },
  userDeatilConsoleSettingGroupsBoxCard: {
    height: '120px',
    padding: '14px 0px 13px 13px',
    border: '1px solid #D9D9D9',
    borderRadius: '6px',
    background: '#F8F8F8',
  },
  userDeatilConsoleSettingGroupsBox: {
    paddingRight: '9px',
    display: 'flex',
    gap: '12px',
    columnGap: '6px',
    flexWrap: 'wrap',
    alignContent: 'flex-start',
  },
  userDeatilConsoleSettingSingleGroupBox: {
    height: '25px',
    width: 'fit-content',
    maxWidth: '162px',
    padding: '3px 10px 5px 10px',
    border: '0.5px solid rgba(217, 217, 217, .5)',
    borderRadius: '4px',
    background: '#FFFFFF',
  },
  // start user detail page

  // start personal setting page
  personalSettingBtnCard: {
    display: 'flex',
    justifyContent: 'flex-end',
    margin: '5px 0px 12px',
  },
  personalSettingBtn: {
    padding: '9px 22px 9px 15px',
    background: '#009DE1',
    textTransform: 'none',
    color: 'white',
    boxShadow: '0',
    borderRadius: '6px',
    '&:hover': {
      backgroundColor: '#007EB4',
    },
    '&.Mui-disabled': {
      color: 'white',
      background: '#009DE1',
      opacity: 0.5,
    },
  },
  personalSettingBtnText: {
    fontSize: '14px',
    lineHeight: '14px',
    marginLeft: '6px',
  },
  personalSettingPageDivider: {
    display: 'flex',
    justifyContent: 'space-between',
    gap: '30px',
    '@media screen and (max-width:1199px)': {
      flexDirection: 'column',
      gap: '20px',
    },
  },
  personalSettingPageDetailsCard: {
    width: '100%',
    background: '#FFFFFF',
    border: '0.5px solid rgba(217, 217, 217, .5)',
    borderRadius: '8px',
    minHeight: '356px',
    padding: '32px',
    '@media screen and (max-width:1530px)': {
      padding: '32px 20px',
    },
  },
  personalSettingPageDetailsCardDivider: {
    display: 'flex',
    justifyContent: 'space-between',
    marginBottom: '24px',
  },
  personalSettingPageDetailsCardHeader: {
    display: 'flex',
    gap: '32px',
    alignItems: 'center',
    width: '100%',
    '@media screen and (max-width:1360px)': {
      gap: '20px',
    },
    '@media screen and (max-width: 599px)': {
      flexWrap: 'wrap',
      justifyContent: 'center',
      textAlign: 'center',
      '& > div': {
        width: '100%',
      },
    },
  },
  personalSettingPageImgCard: {
    width: '120px',
    height: '120px',
    borderRadius: '50%',
    position: 'relative',
  },
  personalSettingImgCard: {
    '@media screen and (max-width: 599px)': {
      marginLeft: 'auto',
      marginRight: 'auto',
    },
  },
  personalSettingPageImgRemoveText: {
    fontSize: '13px',
    lineHeight: '19px',
    fontWeight: '500',
    color: '#FFFFFF',
    position: 'absolute',
    bottom: '16px',
    right: '35px',
  },
  personalSettingPageImgUploadCard: {
    width: '120px',
    height: '120px',
    background: '#F8F8F8',
    borderRadius: '50%',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    flexDirection: 'column',
  },
  personalSettingPageImgUploadText: {
    fontSize: '13px',
    lineHeight: '19px',
    fontWeight: '500',
    marginTop: '3.5px',
  },
  personalSettingPageUserName: {
    fontSize: '28px',
    lineHeight: '40px',
    fontWeight: '500',
    maxWidth: '390px',
    '@media screen and (min-width: 900px) and (max-width: 1199px)': {
      maxWidth: '650px',
    },
    '@media screen and (min-width: 600px) and (max-width: 899px)': {
      maxWidth: '380px',
    },
    '@media screen and (min-width: 400px) and (max-width: 599px)': {
      maxWidth: '235px',
      fontSize: '22px',
    },
    '@media screen and (max-width: 599px)': {
      marginLeft: 'auto',
      marginRight: 'auto',
    },
    '@media screen and (max-width: 399px)': {
      maxWidth: '155px',
      fontSize: '20px',
    },
    whiteSpace: 'pre',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    '&::after': {
      content: "''",
      display: 'block',
    },
  },
  personalSettingPageUserRole: {
    fontSize: '15px',
    lineHeight: '24px',
    marginBottom: '4px',
    color: '#939598',
  },
  personalSettingPageUserEmail: {
    fontSize: '15px',
    color: '#939598',
    maxWidth: '390px',
    '@media screen and (min-width: 900px) and (max-width: 1199px)': {
      maxWidth: '650px',
    },
    '@media screen and (min-width: 600px) and (max-width: 899px)': {
      maxWidth: '380px',
    },
    '@media screen and (min-width: 400px) and (max-width: 599px)': {
      maxWidth: '235px',
    },
    '@media screen and (max-width: 599px)': {
      marginLeft: 'auto',
      marginRight: 'auto',
    },
    '@media screen and (max-width: 399px)': {
      maxWidth: '155px',
    },
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    '&::after': {
      content: "''",
      display: 'block',
    },
  },
  personalSettingPageEmailTitle: {
    fontSize: '14px',
    lineHeight: '20px',
    marginTop: '4px',
  },
  personalSettingPagePasswordCard: {
    width: '100%',
    background: '#FFFFFF',
    border: '0.5px solid rgba(217, 217, 217, .5)',
    borderRadius: '8px',
    height: '140px',
    padding: '32px',
    marginTop: '20px',
    '@media screen and (max-width:1530px)': {
      padding: '32px 20px',
    },
  },
  personalSettingPagePasswordCardHeader: {
    display: 'flex',
    alignItems: 'center',
    marginBottom: '20px',
  },
  personalSettingPagePasswordCardHeaderText: {
    fontSize: '20px',
    fontWeight: '500',
    lineHeight: '20px',
    marginLeft: '8px',
  },
  personalSettingPagePasswordCardButton: {
    textTransform: 'none',
    color: '#009DE1',
    fontWeight: 400,
    padding: 'unset',
  },
  personalSettingPagePasswordCardNewPasswordBox: {
    display: 'flex',
    marginTop: '4px',
    justifyContent: 'space-between',
  },
  // end personal setting page

  // start corporate profile page
  corporateProfilePageCard: {
    width: '100%',
    background: '#FFFFFF',
    border: '0.5px solid rgba(217, 217, 217, .5)',
    borderRadius: '8px',
    padding: '32px',
    '@media screen and (max-width:1530px)': {
      padding: '32px 20px',
    },
  },
  corporateProfilePageCardHeader: {
    display: 'flex',
    gap: '8px',
    alignItems: 'center',
    height: '29px',
    marginBottom: '24px',
  },
  corporateProfilePageCardHeaderTitle: {
    fontSize: '20px',
    fontWeight: '500',
    lineHeight: '20px',
    wordBreak: 'break-word',
  },
  inputLevelCorporateName: {
    fontSize: '14px',
    lineHeight: '20px',
  },
  inputLevel: {
    fontSize: '14px',
    lineHeight: '20px',
    marginTop: '4px',
  },
  countryCodeModel: {
    borderRadius: '6px',
    top: '45px',
    zIndex: 1300,
  },
  countryPopperBox: {
    maxHeight: '226px',
    background: '#FFFFFF',
    boxShadow: '0px 0px 0px #FFFFFF',
    border: '1px solid #D9D9D9',
    borderRadius: '6px',
    opacity: '1',
  },
  countryPopperBoxScrollBar: {
    maxHeight: '183px',
  },
  countryPopperScrollBar: {
    height: '183px',
    overflowX: 'hidden',
    borderRadius: '6px',
  },
  countryPopperMenuItem: {
    '&:not(:last-child),&:not(:first-child)': {
      borderBottom: '1px solid #d9d9d9',
    },
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    width: '99.99%',
    height: '37px',
    '&:hover': {
      backgroundColor: '#EAEAEB',
    },
    '@media screen and (max-width:1530px)': {
      padding: '6px',
    },
  },
  countryPopperMenuItemBox: {
    display: 'flex',
    justifyContent: 'space-between',
    width: '100%',
  },
  countryPopperMenuItemName: {
    marginLeft: '8px',
    marginRight: '4px',
    fontSize: '12px',
    fontWeight: '400',
    maxWidth: '200px',
    '@media screen and (max-width:1700px)': {
      maxWidth: '160px',
    },
    '@media screen and (max-width:1650px)': {
      maxWidth: '150px',
    },
    '@media screen and (max-width:1600px)': {
      maxWidth: '140px',
    },
    '@media screen and (max-width:1550px)': {
      maxWidth: '130px',
    },
    '@media screen and (max-width:1500px)': {
      maxWidth: '120px',
    },
    '@media screen and (max-width:1400px)': {
      maxWidth: '100px',
    },
    '@media screen and (max-width:1280px)': {
      maxWidth: '70px',
    },
    overflow: 'hidden',
    display: 'block',
    textOverflow: 'ellipsis',
    whiteSpace: 'pre',
    '&::after': {
      content: "''",
      display: 'block',
    },
  },
  countryPopperMenuItemDialCode: {
    fontSize: '12px',
    fontWeight: '400',
  },
  countryPhoneInput: {
    '& .MuiOutlinedInput-input': {
      padding: '9px 8px !important',
    },
    'input::-ms-reveal,input::-ms-clear': {
      display: 'none',
    },
    // '&:hover .MuiOutlinedInput-notchedOutline': {
    //   borderColor: '#939598 !important',
    // },
    // '& .MuiOutlinedInput-root': {
    //   borderRadius: '6px',
    //   '&.Mui-focused fieldset': {
    //     border: '1px solid #939598 !important',
    //   },
    // },
  },
  flagSelectArrow: {
    margin: '2px 8px 0px 8px',
    width: '20px',
    height: '20.6px',
    '& > g > path': {
      strokeWidth: 1.5,
    },
  },

  stateCountryPopperMenuItemName: {
    marginLeft: '8px',
    marginRight: '4px',
    fontSize: '12px',
    fontWeight: '400',
    width: 'calc(100% - 40px)',
    overflow: 'hidden',
    display: 'block',
    textOverflow: 'ellipsis',
    whiteSpace: 'pre',
    '&::after': {
      content: "''",
      display: 'block',
    },
  },
  stateCountrySelectBox: {
    boxShadow: 'none',
    backgroundColor: '#ffffff',
    width: '100%',
    height: '39px',
    fontSize: '14px',
    fontWeight: '400',
    display: 'flex',
    alignItems: 'center',
    paddingLeft: '20px',
    paddingRight: '10px',
    background: '#FFFFFF 0% 0% no-repeat padding-box',
    borderRadius: '6px',
    justifyContent: 'space-between',
    opacity: '1',
    cursor: 'pointer',
    '&:hover': {
      border: '1px solid #939598',
    },
  },
  stateCountrySelectItem: {
    fontSize: '14px',
    fontWeight: '400',
    maxWidth: '250px',
    '@media screen and (max-width:1650px)': {
      maxWidth: '210px',
    },
    '@media screen and (max-width:1550px)': {
      maxWidth: '190px',
    },
    '@media screen and (max-width:1450px)': {
      maxWidth: '150px',
    },
    '@media screen and (max-width:1280px)': {
      maxWidth: '130px',
    },
    display: 'block',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    whiteSpace: 'pre',
    height: '20px',
  },
  stateCountrySelectText: {
    fontSize: '14px',
    fontWeight: '400',
    color: '#939598',
    height: '20px',
  },
  corporatePageBtnCard: {
    marginTop: '20px',
    width: '100%',
    background: '#FFFFFF',
    border: '0.5px solid rgba(217, 217, 217, .5)',
    borderRadius: '8px',
    padding: ' 18px 32px',
    height: '64px',
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
  },
  corporatePageBtnCardHeader: {
    display: 'flex',
    gap: '8px',
    alignItems: 'center',
    height: '29px',
  },
  corporatePageIconBtn: {
    padding: '0px',
    borderRadius: '30%',
    color: '#009DE1',
    cursor: 'unset',
    // '&:hover': {
    //   color: '#007EB4',
    //   '& svg': {
    //     '& path': {
    //       stroke: '#007EB4',
    //     },
    //   },
    // },
  },
  corporatePageBtnCardBtnText: {
    fontSize: '14px',
    fontWeight: '500',
    lineHeight: '20px',
    paddingRight: '9px',
  },
  subscriptionDataCard: {
    display: 'flex',
    gap: '105px',
    marginBottom: '24px',
  },
  subscriptionCardTitle: {
    fontSize: '14px',
    fontWeight: '500',
    lineHeight: '20px',
    marginBottom: '6px',
  },
  subscriptionCardText: {
    fontSize: '14px',
    lineHeight: '20px',
  },
  cardTitleDashboard: {
    fontSize: '18px',
    fontWeight: '500',
    lineHeight: '21px',
    '@media screen and (max-width: 1366px)': {
      fontSize: '16px',
    },
    '@media screen and (max-width: 770px)': {
      maxWidth: 'unset',
    },
  },
  cardSubTitleDashboard: {
    fontSize: '14px',
    fontWeight: '500',
    '@media screen and (max-width: 1366px)': {
      fontSize: '12px',
    },
    '@media screen and (max-width: 770px)': {
      maxWidth: 'unset',
    },
  },

  cardSelectBoxPlaceholderDashboard: {
    flexGrow: 2,
    lineHeight: 1,
    fontSize: '13px',
    marginTop: '-2px',
    '@media screen and (max-width: 1366px)': {
      fontSize: '12px',
    },
  },
  remoteSettingDividers: {
    content: '" "',
    width: '1px',
    height: '75px',
    backgroundColor: '#d9d9d9',
    marginTop: '26px',
  },
  listWindowContainer: {
    '&::-webkit-scrollbar': {
      display: 'none',
    },
    // for firefox scrollbar disable
    '-ms-overflow-style': 'none',
    'scrollbar-width': 'none',
  },

  dashboardDoughnutChartContainer: {
    maxWidth: '1200px',
    margin: '20px auto',
    display: 'flex',
    justifyContent: 'space-between',
    gap: '18px',
    '@media screen and (max-width: 1280px)': {
      flexDirection: 'column',
    },
  },
  dashboardDoughnutChart: {
    backgroundColor: '#fff',
    width: '50%',
    borderRadius: '8px',
    border: '1px solid rgba(222, 223, 223, 0.5)',
    '@media screen and (max-width: 1280px)': {
      width: '100%',
    },
  },

  doughnutChartLanguageContainer: {
    width: '50%',

    '@media screen and (max-width: 1280px)': {
      width: '40%',
    },
  },
  dashboardRemoteSettingsContainer: {
    maxWidth: '1200px',
    margin: '20px auto 0px auto',
    display: 'flex',
    justifyContent: 'space-between',
    gap: '18px',
    '@media screen and (max-width: 1280px)': {
      flexDirection: 'column',
    },
  },

  dashboardRemoteSettings: {
    backgroundColor: '#fff',
    width: '50%',
    borderRadius: '8px',
    border: '1px solid rgba(222, 223, 223, 0.5)',
    '@media screen and (max-width: 1280px)': {
      width: '100%',
    },
  },

  lostDeviceAndSimExpired: {
    display: 'flex',
    width: '50%',
    gap: '18px',
    '@media screen and (max-width: 1280px)': {
      width: '100%',
    },
  },

  // 404 page
  errorPageTextBox: {
    position: 'absolute',
    bottom: '15%',
    textAlign: 'center',
    '@media screen and (max-width: 1366px)': {
      bottom: '11%',
    },
  },
  errorPageTitleText: {
    fontSize: '34px',
    color: '#FFFFFF',
    fontWeight: '500',
    '@media screen and (max-width: 920px)': {
      color: '#000000',
    },
  },
  errorPageSubTitleText: {
    marginTop: '14px',
    fontSize: '20px',
    color: '#FFFFFF',
    fontWeight: '400',
    '@media screen and (max-width: 920px)': {
      color: '#000000',
    },
  },
  backDashboardBtn: {
    marginTop: '23px',
    padding: '12px 25px',
    // height: '38px',
    background: '#009DE1',
    textTransform: 'none',
    color: 'white',
    boxShadow: '0',
    borderRadius: '6px',
    '&:hover': {
      backgroundColor: '#007EB4',
      color: 'white',
    },
  },
  backDashboardBtnText: {
    fontSize: '14px',
    lineHeight: '20px',
    marginLeft: '8px',
  },
  backDashboardBtnIcon: {
    width: '20px',
    height: '20px',
    '& path': {
      fill: '#fff',
    },
  },
  // 404 page

  // breadcrumb box
  breadcrumbBox: {
    paddingLeft: '40px',
    paddingTop: '30px',
    backgroundColor: '#f8f8f8',
    '@media screen and (max-width:1440px)': {
      paddingLeft: '30px',
    },
    '@media screen and (max-width:1336px)': {
      paddingLeft: '20px',
    },
  },
  // disables input field
  disableInputField: {
    height: '38px',
    backgroundColor: '#f8f8f8',
    padding: '8px 16px 10px 16px',
    border: '1px solid #D9D9D9',
    borderRadius: '6px',
    marginTop: '6px',
    marginBottom: '16px',
  },
  disableInputFieldText: {
    color: '#939598',
    fontSize: '14px',
    whiteSpace: 'pre',
    textOverflow: 'ellipsis',
    overflow: 'hidden',
    '&::after': {
      content: "''",
      display: 'block',
    },
  },

  // security settings page
  securitySettingsCard: {
    maxWidth: '780px',
    width: '100%',
    background: '#FFFFFF',
    border: '0.5px solid rgba(217, 217, 217, .5)',
    borderRadius: '8px',
    minHeight: '181px',
    padding: '31px',
    marginTop: '-5px',
  },
  securitySettingsTitleSection: {
    display: 'flex',
    gap: '8px',
    alignItems: 'center',
    minHeight: '29px',
    marginBottom: '24px',
  },
  securitySettingsCardTitle: {
    fontSize: '20px',
    fontWeight: '500',
    lineHeight: '20px',
    wordBreak: 'break-word',
  },
  securitySettingsCardText: {
    fontSize: '14px',
    fontWeight: '500',
    marginBottom: '16px',
    lineHeight: '20px',
  },
  securitySettingsBtn: {
    padding: '0px',
    minWidth: '0px',
    marginTop: '-7px',
  },
  securityToggleDialog: {
    '& .MuiDialog-paper': {
      width: '460px',
      // minHeight: '260px',
      borderRadius: '12px',
      padding: '24px',
      alignSelf: 'flex-start',
      marginTop: '180px',
      '& .MuiDialogTitle-root': {
        padding: 0,
        fontSize: '18px',
        lineHeight: '26px',
        maxWidth: '356px',
        fontWeight: 'bold',
      },
      '& .MuiDialogContent-root': {
        padding: 0,
      },
      '& .MuiDialogActions-root': {
        padding: 0,
        marginLeft: 'auto',
      },
    },
  },
  securityDialogCloseIcon: {
    position: 'absolute',
    right: 16,
    top: 17,
  },
  securityDialogContent: {
    marginTop: '10px',
    maxWidth: '294px',
  },
  securityDialogSubContent: {
    fontSize: '14px',
    lineHeight: '21.34px',
    fontWeight: 300,
  },
  // Export log Data Dialog
  exportLogDataMainBox: {
    padding: '24px',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'flex-start',
    columnGap: '14px',
    position: 'relative',
    '@media screen and (max-width: 550px)': {
      flexDirection: 'column',
      alignItems: 'center',
    },
  },
  exportLogDataCloseIcon: {
    position: 'absolute',
    top: '18px',
    right: '18px',
    cursor: 'pointer',
    width: '24px',
    height: '24px',
    borderRadius: '50%',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',

    '&:hover': {
      backgroundColor: '#0000000a',
    },
  },
  exportLogDataDetailsBox: {
    display: 'flex',
    flexDirection: 'column',
    rowGap: '10px',
  },
  exportLogDataTitleText: {
    fontSize: '18px',
    fontWeight: 700,
    color: '#313133',
    lineHeight: '26px',
    maxWidth: '340px',
    '@media screen and (max-width: 550px)': {
      maxWidth: 'unset',
    },
  },
  exportLogDataText: {
    fontSize: '14px',
    fontWeight: 400,
    color: '#313133',
    lineHeight: '22px',
  },
  exportLogDataBtnBox: {
    display: 'flex',
    justifyContent: 'flex-end',
    columnGap: '8px',
    paddingTop: '6px',
  },
  exportLogDataCancleBtn: {
    height: '44px',
    textTransform: 'none',
    backgroundColor: '#fff',
    border: '1px solid #d9d9d9',
    borderRadius: '6px',
    color: '#313133',
    fontSize: '16px',
    fontWeight: 500,
    width: '112px',
    padding: '10px',

    '&:hover': {
      backgroundColor: '#EAEAEB',
    },
  },
  exportLogDataExportBtn: {
    height: '44px',
    textTransform: 'none',
    backgroundColor: '#009de1',
    borderRadius: '6px',
    color: '#fff',
    fontSize: '16px',
    fontWeight: 500,
    minWidth: '110px',
    padding: '10px',

    '&:hover': {
      backgroundColor: '#007EB4',
    },
    '@media screen and (max-width: 350px)': {
      fontSize: '15px',
    },
  },
  exportLogDataExportBtnBox: {
    display: 'flex',
    alignItems: 'center',
    gap: '4px',
  },
  inputText: {
    fontWeight: '300',
    maxWidth: '200px',
    fontSize: '11px',
    color: '#939598',
    padding: '0',
    margin: '0',
    position: 'absolute',
    lineHeight: '14px',
  },
  toggleNoteText: {
    fontWeight: '300',
    maxWidth: '300px',
    fontSize: '11px',
    color: '#939598',
    padding: '0',
    margin: '0',
    lineHeight: '14px',
  },
  progressDialog: {
    '& .MuiDialog-paper': {
      width: '808px',
      maxWidth: '808px',
      borderRadius: '12px',
      padding: '22px 32px 32px',
      alignSelf: 'flex-start',
      marginTop: '180px',
      '& .MuiDialogTitle-root': {
        padding: 0,
        fontSize: '18px',
        lineHeight: '26px',
        maxWidth: '356px',
        fontWeight: 'bold',
      },
      '& .MuiDialogContent-root': {
        padding: 0,
      },
      '& .MuiDialogActions-root': {
        padding: 0,
        marginLeft: 'auto',
      },
    },
  },
  progressDialogUpdatingText: {
    fontSize: '14px',
    fontWeight: '700',
    marginBottom: '17px',
    lineHeight: '19px',
  },
  progressDialogContent: {
    marginTop: '16px',
  },

  errorCardBulkAction: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    background: '#E1341E',
    borderRadius: '3px',
    marginBottom: '6px',
    padding: '4.5px 8px',
  },
  errorCardBulkActionBox: {
    display: 'flex',
    alignItems: 'center',
    gap: '10px',
  },
  errorCardBulkActionBoxText: { fontSize: '14px', color: '#FFFFFF' },
  /**
   * code by bjit end
   */
}));
