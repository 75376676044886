import * as React from 'react';
import Button from '@mui/material/Button';
import { styled } from '@mui/material/styles';
import Box from '@mui/material/Box';
import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';

import DialogActions from '@mui/material/DialogActions';
import Typography from '@mui/material/Typography';
import IconButton from '@mui/material/IconButton';
// import RedirectDialog from '../Modecules/RedirectDialog';
// icons
import { ReactComponent as CloseIcon } from '../../images/icon/close.svg';
// modal icons
import { ReactComponent as ModalDeleteIcon } from '../../images/icon/modal_delete.svg';
// import useSnackbar from '../../hooks/useSnackbar';
// mui custom style
import { useStyles } from '../Templates/CommonStyle';
import useGroupDelete from '../../hooks/useGroupDelete';
import { sendGAEvent } from '../../hooks/useGAEvent';
import Loader from './Loader';
import useSwitchLocaleLanguage from '../../hooks/useSwitchLocaleLanguage';

// common dialog
const CustomDialog = styled(Dialog)({
  '& .MuiDialog-paper': {
    width: '460px',
    borderRadius: '12px',
    padding: '24px',
    alignSelf: 'flex-start',
    marginTop: '180px',
    '& .MuiDialogTitle-root': {
      padding: 0,
      fontSize: '18px',
      lineHeight: '26px',
      maxWidth: '285px',
      fontWeight: 'bold',
      letterSpacing: 0,
    },
    '& .MuiDialogContent-root': {
      padding: 0,
    },
    '& .MuiDialogActions-root': {
      padding: 0,
      marginLeft: 'auto',
    },
  },
}) as typeof Dialog;

type Props = {
  open: boolean;
  onClose: (isPositionButton?: boolean) => void;
  icon: string;
  title: string;
  subTitle: string;
  btnBgColor: string;
  btnBgColorHover: string;
  btnTextNegative: string;
  btnTextPositive: string;
  notDelete?: string;
  isLoading?: boolean;
};

const CommonDialog = React.memo<Props>((props): JSX.Element => {
  const { t } = useSwitchLocaleLanguage();
  // custom styles
  const classes = useStyles();
  // const { displaySnackbar } = useSnackbar();
  const { open, onClose, isLoading = false } = props;
  const {
    onClickDeleteGroupButton,
    isCallingGroupDeleteApi,
    deleteAPIMsg,
    // groupDeleteError,
    // onClickGroupList,
  } = useGroupDelete();

  const deleteBtnAction = () => {
    if (!props.notDelete) {
      onClickDeleteGroupButton();
      if (!isCallingGroupDeleteApi && deleteAPIMsg === 'SUCCESSFULLY_DELETED') {
        onClose();
      }
      // GA event tag
      sendGAEvent('press_btn', 'btn_name', 'delete');
    } else {
      onClose(true);
    }
  };

  const cancelBtnAction = () => {
    onClose();
    // GA event tag
    sendGAEvent('press_btn', 'btn_name', 'cancel');
  };

  const closeBtnAction = () => {
    onClose();
    // GA event tag
    sendGAEvent('press_btn', 'btn_name', 'exit');
  };

  return (
    <div>
      {/* {groupDeleteError.errorMessage && (
        <RedirectDialog
          open
          onClickGroupList={onClickGroupList}
          message={groupDeleteError.errorMessage}
        />
      )} */}
      <CustomDialog aria-labelledby="Restore Factory Settings" open={open}>
        <Box className={`${classes.dialogBox} res-dialog`}>
          {!props.notDelete && (
            <Box className={`${classes.dialogIcon} res-dialog-icon`}>
              <ModalDeleteIcon />
            </Box>
          )}
          <Box sx={{ width: '100%' }}>
            <DialogTitle className="res-dialog-title">
              {props.title}
              <IconButton
                aria-label="close"
                onClick={closeBtnAction}
                className={classes.dialogCloseIcon}
                disabled={isLoading}
              >
                <CloseIcon className={classes.close} />
              </IconButton>
            </DialogTitle>
            <DialogContent
              className={`${classes.dialogContentCommon} res-dialog-text`}
            >
              <Typography variant="body2" className={classes.dialogSubTitle}>
                {props.subTitle}
              </Typography>
            </DialogContent>
            <DialogActions
              className={`${classes.dialogActionsCommon} res-dialog-btns`}
            >
              <Button
                className={classes.btnAction}
                onClick={cancelBtnAction}
                disableRipple
                disabled={isLoading}
              >
                {props.btnTextNegative}
              </Button>
              <Button
                autoFocus
                className={`${classes.btnAction} ${classes.btnActionCommon}`}
                // onClick={handleFactorySettings}
                sx={{
                  backgroundColor: props.btnBgColor,
                  '&:hover': {
                    backgroundColor: `${props.btnBgColorHover}!important`,
                  },
                  '&.Mui-disabled': {
                    color: 'white',
                    opacity: 0.5,
                  },
                }}
                onClick={() => deleteBtnAction()}
                disableRipple
                disabled={isCallingGroupDeleteApi}
              >
                {isCallingGroupDeleteApi ? (
                  <Box
                    sx={{ display: 'flex', alignItems: 'center', gap: '8px' }}
                  >
                    {t('userDeleteDialog.label.deletingBtn')}{' '}
                    <Loader colorCode="#fff" />
                  </Box>
                ) : (
                  // eslint-disable-next-line react/jsx-no-useless-fragment
                  <>{isLoading? <Loader colorCode="#fff" /> : props.btnTextPositive}</>
                )}
              </Button>
            </DialogActions>
          </Box>
        </Box>
      </CustomDialog>
      {/* restore factory dialog end */}
    </div>
  );
});

export default CommonDialog;
