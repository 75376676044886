import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import DoneIcon from '@mui/icons-material/Done';
import {
  Box,
  Typography,
  MenuItem,
  FormControl,
  Popper,
  ClickAwayListener,
  Paper,
  Tooltip,
  Skeleton,
  Button,
} from '@mui/material';

// import Filter from './Filter';
import { useCallback, useRef, useState } from 'react';
import { v4 as uuidv4 } from 'uuid';
import SimpleBarReact from 'simplebar-react';
import SearchField from './SearchField';
import { useStyles } from '../Templates/CommonStyle';
import styles from './DeviceFilter.module.scss';
import type { FilterInput } from '../../types/inputs/filterInput';
import { ReactComponent as SearchIcon } from '../../images/icon/search.svg';
import { sendGAEvent } from '../../hooks/useGAEvent';
import { DeviceGroupInfo } from '../../types/apis/deviceGroupListApi';
import useSwitchLocaleLanguage from '../../hooks/useSwitchLocaleLanguage';
import useLocale, { LOCALE_CODE } from '../../hooks/useLocale';
import Loader from '../Atoms/Loader';
import { ReactComponent as ExportIcon } from '../../images/icon/export.svg';

export type FilterValue = {
  onClickFilterSearch: (inputData: FilterInput) => void;
  filterInputData: FilterInput;
  onGroupListScroll: () => void;
  isGroupListApiCalling: boolean;
};
type Props = {
  filterValue: FilterValue;
  handleSelectBoxClick: (
    event: React.MouseEvent<HTMLElement, MouseEvent>,
  ) => void;
  selectedGroup: string;
  totalGroup: DeviceGroupInfo[];
  selectAnchor: null | HTMLElement;
  selectOpen: boolean;
  handleDropDownSearch: (data: React.ChangeEvent<HTMLInputElement>) => void;
  handleSelectGroupOpen: (event: MouseEvent | TouchEvent) => void;
  handleSelectGroup: (grouInfo: DeviceGroupInfo) => void;
  onClickExportData: () => void;
  disabled?: boolean;
  isCallingDownloadApi: boolean;
};
const DeviceFilter = ({
  filterValue,
  handleSelectBoxClick,
  selectedGroup,
  totalGroup,
  selectAnchor,
  selectOpen,
  handleDropDownSearch,
  handleSelectGroupOpen,
  handleSelectGroup,
  disabled,
  onClickExportData,
  isCallingDownloadApi,
}: Props) => {
  const { localeLanguage } = useLocale();
  const classes = useStyles();
  const { t } = useSwitchLocaleLanguage();
  const observer = useRef<IntersectionObserver | null>(null);
  const [tooltipEnabledGroupName, setTooltipEnabledGroupName] = useState(false);
  const lastUserElementRef = useCallback(
    (node) => {
      if (observer.current) observer.current.disconnect();
      observer.current = new IntersectionObserver((entries) => {
        if (entries[0].isIntersecting && !filterValue.isGroupListApiCalling) {
          filterValue.onGroupListScroll();
        }
      });
      // eslint-disable-next-line @typescript-eslint/no-unsafe-argument
      if (node) observer.current.observe(node);
    },
    // eslint-disable-next-line
    [],
  );
  const handleShouldShowGroupTooltipName = (
    event: React.MouseEvent<Element>,
  ) => {
    const element = event.currentTarget as HTMLElement;
    const clientWidth = element.getBoundingClientRect().width;

    // Set the element's overflow to visible
    element.style.overflow = 'visible';
    element.style.width = 'fit-content';

    // Get the scroll width
    const scrollWidth = element.getBoundingClientRect().width;

    // Set the element's overflow back to hidden
    element.style.overflow = 'hidden';
    element.style.width = '228px';

    if (scrollWidth > clientWidth) {
      setTooltipEnabledGroupName(true);
    } else {
      setTooltipEnabledGroupName(false);
    }
  };

  // const finalGroupName = (name: any) => {
  //   let decodedGroupName: string;
  //   try {
  //     decodedGroupName = decodeURIComponent(JSON.stringify(name)?.slice(1, -1));
  //   } catch (err) {
  //     decodedGroupName = JSON.stringify(name)?.slice(1, -1);
  //   }

  //   return decodedGroupName;
  // };

  return (
    <Box>
      {filterValue.onClickFilterSearch}
      <Box
        sx={{
          width: '100%',
          display: 'flex',
          justifyContent: 'space-between',
        }}
        className="filter-box"
      >
        <Box sx={{ width: '25.1%' }} className="filter-box-item">
          <SearchField
            disable={disabled}
            clickFilterSearch={(val) => {
              filterValue.onClickFilterSearch({
                searchId: uuidv4(),
                searchText: decodeURIComponent(val.toString()).trim(),
              });

              // GA tag
              sendGAEvent('search_kw', 'txt_search_kw', val.toString());
            }}
            placeholder={t('groupDetail.placeholder.searchDevice')}
            responsiveClass={
              localeLanguage === LOCALE_CODE.FR
                ? 'search-input-fr'
                : 'search-input'
            }
          />
        </Box>
        <Box
          sx={{
            display: 'flex',
            alignItems: 'end',
            '@media screen and (max-width: 1280px)': {
              width: '100%',
              flexDirection: 'column',
              gap: '12px',
              justifyContent: 'center',
            },
          }}
        >
          <Box
            sx={{
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'space-between',
            }}
            className="filter-box-item"
          >
            {/* Groups */}
            <Box
              sx={{ display: 'flex', alignItems: 'center' }}
              className="filter-box-item-child"
            >
              <Typography
                fontSize="13px"
                sx={{ fontWeight: 500, paddingRight: '12px' }}
              >
                {t('groupList.label.singleGroup')}
              </Typography>
              {/* Search Box */}
              <FormControl
                sx={{ width: '230px', height: '38px' }}
                className="filter-box-item-select"
              >
                <Box
                  onClick={handleSelectBoxClick}
                  className="select-group"
                  sx={{
                    boxShadow: 'none',
                    border: selectOpen
                      ? '1px solid #939598'
                      : '1px solid #d9d9d9',
                    backgroundColor: '#ffffff',
                    width: '100%',
                    height: '39px',
                    fontSize: '14px',
                    fontWeight: '400',
                    display: 'flex',
                    alignItems: 'center',
                    paddingLeft: '20px',
                    paddingRight: '10px',
                    background: '#FFFFFF 0% 0% no-repeat padding-box',
                    borderRadius: '6px',
                    justifyContent: 'space-between',
                    opacity: '1',
                    cursor: 'pointer',
                    '&:hover': {
                      border: '1px solid #939598',
                    },
                  }}
                >
                  <Tooltip
                    placement="top"
                    title={selectedGroup}
                    disableHoverListener={!tooltipEnabledGroupName}
                    onMouseEnter={handleShouldShowGroupTooltipName}
                    arrow
                    sx={{ marginTop: 0 }}
                    PopperProps={{
                      modifiers: [
                        {
                          name: 'offset',
                          options: {
                            offset: [-7, -7],
                          },
                        },
                      ],
                    }}
                    componentsProps={{
                      tooltip: {
                        className: classes.tooltipStyle,
                      },
                    }}
                  >
                    <Typography
                      sx={{
                        fontSize: '14px',
                        fontWeight: '400',
                        width: '178px',
                        display: 'block',
                        overflow: 'hidden',
                        textOverflow: 'ellipsis',
                        whiteSpace: 'pre',
                        height: '22px',
                      }}
                    >
                      {selectedGroup === 'All'
                        ? t('groupList.label.all')
                        : selectedGroup}
                    </Typography>
                  </Tooltip>
                  <KeyboardArrowDownIcon
                    sx={{ width: '20px', height: '20px' }}
                  />
                </Box>
                <Popper
                  placement="bottom-start"
                  anchorEl={selectAnchor}
                  open={selectOpen}
                  className={classes.selectGroupModel}
                >
                  <ClickAwayListener onClickAway={handleSelectGroupOpen}>
                    <Paper className={classes.selectGroupPopperBox}>
                      <Box
                        sx={{
                          borderRadius: '6px 6px 0px 0px',
                          padding: '6px 8px',
                          position: 'relative',
                          width: 'fit-content',
                          backgroundColor: '#ffffff',
                          cursor: 'pointer',
                          height: '45px',
                        }}
                      >
                        <SearchIcon
                          style={{
                            width: '16px',
                            height: '16px',
                            position: 'absolute',
                            right: '20px',
                            top: '14px',
                            zIndex: '0',
                            color: '#939598',
                          }}
                        />
                        <input
                          type="text"
                          placeholder={t('groupList.placeholder.search')}
                          onChange={handleDropDownSearch}
                          className={styles.searchDropdown}
                          // eslint-disable-next-line jsx-a11y/no-autofocus
                          autoFocus
                        />
                      </Box>
                      <Box className={classes.selectGroupPopperBoxScrollBar}>
                        <SimpleBarReact
                          style={{
                            maxHeight: '180px',
                            overflowX: 'hidden',
                            marginRight: '-4px',
                            borderBottomRightRadius: '12px',
                            borderBottomLeftRadius: '6px',
                          }}
                          autoHide={false}
                        >
                          <MenuItem
                            sx={{
                              border: '1px solid #D9D9D9',
                              borderRight: 'none',
                              borderLeft: 'none',
                              display: 'flex',
                              flexDirection: 'row',
                              justifyContent: 'space-between',
                              width: '228px',
                              height: '36px',
                              fontSize: '12px',
                              fontWeight: '400',
                              '&:hover': {
                                backgroundColor: '#EAEAEB',
                              },
                              backgroundColor:
                                selectedGroup === 'All' ? '#EAEAEB' : '#ffffff',
                            }}
                            value={0}
                            onClick={() => {
                              handleSelectGroup({
                                groupName: 'All',
                                groupId: 0,
                              });
                            }}
                          >
                            {t('groupList.label.all')}
                            {selectedGroup === 'All' && (
                              <DoneIcon
                                sx={{
                                  height: '20px',
                                  width: '20px',
                                  color: '#009DE1',
                                }}
                              />
                            )}
                          </MenuItem>
                          {totalGroup?.length > 0 &&
                            totalGroup?.map((item, i) => (
                              <MenuItem
                                sx={{
                                  border: '1px solid #D9D9D9',
                                  borderRight: 'none',
                                  borderLeft: 'none',
                                  display: 'flex',
                                  flexDirection: 'row',
                                  justifyContent: 'space-between',
                                  width: '228px',
                                  height: '36px',
                                  '&:hover': {
                                    backgroundColor: '#EAEAEB',
                                  },
                                  backgroundColor:
                                    selectedGroup === item.groupName
                                      ? '#EAEAEB'
                                      : '#ffffff',
                                }}
                                value={item.groupId}
                                onClick={() => {
                                  handleSelectGroup({
                                    groupName: item.groupName,
                                    groupId: item.groupId,
                                  });
                                }}
                                ref={
                                  i + 1 === totalGroup.length
                                    ? lastUserElementRef
                                    : null
                                }
                              >
                                <Tooltip
                                  placement="top"
                                  title={item.groupName}
                                  disableHoverListener={
                                    !tooltipEnabledGroupName
                                  }
                                  onMouseEnter={
                                    handleShouldShowGroupTooltipName
                                  }
                                  arrow
                                  sx={{ marginTop: 0 }}
                                  PopperProps={{
                                    modifiers: [
                                      {
                                        name: 'offset',
                                        options: {
                                          offset: [-7, -7],
                                        },
                                      },
                                    ],
                                  }}
                                  componentsProps={{
                                    tooltip: {
                                      className: classes.tooltipStyle,
                                    },
                                  }}
                                >
                                  <Typography
                                    sx={{
                                      fontSize: '12px',
                                      fontWeight: '400',
                                      width:
                                        selectedGroup === item.groupName
                                          ? '208px'
                                          : '228px',
                                      overflow: 'hidden',
                                      display: 'block',
                                      textOverflow: 'ellipsis',
                                      whiteSpace: 'pre',
                                      '&::after': {
                                        content: "''",
                                        display: 'block',
                                      },
                                    }}
                                  >
                                    {item.groupName}
                                  </Typography>
                                </Tooltip>
                                {selectedGroup
                                  .toLocaleLowerCase()
                                  .trim()
                                  .localeCompare(
                                    item.groupName
                                      .toString()
                                      .toLocaleLowerCase()
                                      .trim(),
                                  ) === 0 && (
                                  <DoneIcon
                                    sx={{
                                      height: '20px',
                                      width: '20px',
                                      color: '#009DE1',
                                    }}
                                  />
                                )}
                              </MenuItem>
                            ))}
                          {filterValue.isGroupListApiCalling && (
                            <>
                              <MenuItem
                                sx={{
                                  border: '1px solid #D9D9D9',
                                  borderRight: 'none',
                                  borderLeft: 'none',
                                  width: '228px',
                                  height: '36px',
                                  backgroundColor: '#ffffff',
                                  paddingLeft: '16px',
                                }}
                              >
                                <Skeleton
                                  sx={{
                                    width: '170px',
                                    height: '20px',
                                  }}
                                />
                              </MenuItem>
                              <MenuItem
                                sx={{
                                  border: '1px solid #D9D9D9',
                                  borderRight: 'none',
                                  borderLeft: 'none',
                                  width: '228px',
                                  height: '36px',
                                  backgroundColor: '#ffffff',
                                  paddingLeft: '16px',
                                }}
                              >
                                <Skeleton
                                  sx={{
                                    width: '170px',
                                    height: '20px',
                                  }}
                                />
                              </MenuItem>
                            </>
                          )}
                        </SimpleBarReact>
                      </Box>
                    </Paper>
                  </ClickAwayListener>
                </Popper>
              </FormControl>
            </Box>
          </Box>

          <Button
            aria-label="ExportData"
            className={classes.exportDataBtn}
            disableRipple
            onClick={() => onClickExportData()}
            disabled={isCallingDownloadApi}
          >
            {isCallingDownloadApi ? (
              <Box className="export-data-btn-box">
                <Loader colorCode="#fff" />
                <Typography
                  className={`${classes.exportDataText} export-data-text`}
                >
                  {t('reportDownloadButton')}
                </Typography>
              </Box>
            ) : (
              <Box className="export-data-btn-box">
                <ExportIcon />
                <Typography
                  className={`${classes.exportDataText} export-data-text`}
                >
                  {t('reportDownloadButton')}
                </Typography>
              </Box>
            )}
          </Button>
        </Box>
      </Box>
    </Box>
  );
};

DeviceFilter.defaultProps = {
  disabled: false,
};

export default DeviceFilter;
