import type {
  ExportDataApiTerminateRequestParameter,
  ExportDataApiResponse,
} from '../types/apis/exportDataApi';
import callApiUtil from '../utils/callApiUtil';
import { API_URL } from '../constants/constants';

/**
 * Eula Update Api
 *
 * @param requestParams request parameter
 * @returns
 */
const callExportLogDataTerminateApi = (
  requestParams: ExportDataApiTerminateRequestParameter,
): Promise<ExportDataApiResponse> => {
  // (POST)
  /*eslint-disable*/

  console.log('Call Terminate API');

  const response = callApiUtil
    .put<ExportDataApiResponse>(API_URL.LOG_DATA_TERMINATE_API, requestParams)
    .then((apiResponse) => apiResponse.data)
    .catch((error) => {
      if (error?.response) {
        return error?.response?.data;
      }
      return error.message;
    });

  return response;
};

export default callExportLogDataTerminateApi;
