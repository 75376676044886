/* eslint-disable no-nested-ternary */
import React, { useState, MouseEvent } from 'react';
import { useNavigate } from 'react-router-dom';
import dayjs from 'dayjs';
import utc from 'dayjs/plugin/utc';
import 'dayjs/locale/fr'; // Import the French locale
import 'dayjs/locale/de'; // Import the German locale
import SimpleBarReact from 'simplebar-react';
import useMediaQuery from '@mui/material/useMediaQuery';
// import { formatDistance } from 'date-fns';
import { Tooltip, Box, Avatar, Typography } from '@mui/material';
import { TFunction } from 'react-i18next';
import useSwitchLocaleLanguage from '../../hooks/useSwitchLocaleLanguage';
import { sendGAEvent } from '../../hooks/useGAEvent';
import { ReactComponent as LastUsedTick } from '../../images/icon/tick-mark.svg';
import { ReactComponent as LastUsedWarning } from '../../images/icon/warning-mark.svg';
import { ReactComponent as ExpiryWarning } from '../../images/icon/alert-warning.svg';
import { ReactComponent as ExpiredIcon } from '../../images/icon/expired.svg';
import { ReactComponent as DataProtectionGroupListIcon } from '../../images/icon/grouptable-data-protection.svg';
import { ReactComponent as DataProtectionGroupListSelectedIcon } from '../../images/icon/grouptable-data-protection-selected.svg';
import { ReactComponent as FeatureLimitGroupListIcon } from '../../images/icon/grouptable-featurelimit.svg';
import { ReactComponent as FeatureLimitGroupListSelectedIcon } from '../../images/icon/grouptable-featurelimit-selected.svg';
import { ReactComponent as WifiSettingGroupListIcon } from '../../images/icon/grouptable-wi-fi-setting.svg';
import { ReactComponent as WifiSettingGroupListSelectedIcon } from '../../images/icon/grouptable-wi-fi-setting-selected.svg';
// import { ReactComponent as WifiSettingGroupSaveHistorySelectedIcon } from '../../images/icon/grouptable-save-history-selected.svg';
// import { ReactComponent as WifiSettingGroupSaveHistoryIcon } from '../../images/icon/grouptable-save-history.svg';
import { ReactComponent as SuspendIcon } from '../../images/icon/suspend.svg';
import useLocale, { LOCALE_CODE } from '../../hooks/useLocale';
import CustomTooltip from './Tooltip/index';

import {
  TableRowData,
  USER_LIST_TABLE_DATA_KEY,
  GROUP_LIST_TABLE_DATA_KEY,
  GROUP_DEVICE_LIST_TABLE_DATA_KEY,
  DEVICE_TABLE_DATA_KEY,
  TRANS_COUNT_TABLE_DATA_KEY,
  TRANS_HISTORY_TABLE_DATA_KEY,
  TO_LANG_TABLE_DATA_KEY,
  TableHeaderData,
} from '../../types/datatable/dataTable.d';

import styles from './TableBodyContainer.module.scss';
import {
  colorsArray,
  PAGE_PATH_NAME,
  STATUS_COLOR_CODE,
} from '../../constants/constants';
import {
  getTimeFormat,
  simExpiryDateFormat,
  statusColorFn,
} from '../../utils/devicelist/deviceListUtil';
import IconWithTooltip from './iconWithTooltip';

dayjs.extend(utc);

const handleTooltipClick = (event: React.MouseEvent<HTMLDivElement>) => {
  event.stopPropagation();
};
const isSafari = /^((?!chrome|android).)*safari/i.test(navigator.userAgent);

const os = ['Windows', 'Linux', 'Mac']; // add your OS values
const osName = os.find((v) => navigator.appVersion.indexOf(v) >= 0);

const { userAgent } = window.navigator;
let browserName;

if (/chrome|chromium|crios/i.exec(userAgent)) {
  browserName = 'chrome';
} else if (/firefox|fxios/i.exec(userAgent)) {
  browserName = 'firefox';
} else if (/safari/i.exec(userAgent)) {
  browserName = 'safari';
} else {
  browserName = 'No browser detection';
}
const isFirefox = browserName === 'firefox';

/**
 * 本コンポーネント呼び出し時に受け取るProps(必須)
 */
type Props = {
  // テーブルに表示する行
  rows: TableRowData[];
  columnLength: number;
  tableSrcPage?: string;
  isCallingApi?: boolean;
  imeiLinkClicked?: ((imei: string) => void) | undefined;
  clickDisable?: boolean;
  columnsWidthSum?: number;
  columns?: TableHeaderData[];
  dataShow: boolean;
  simpleBarRef: React.LegacyRef<SimpleBarReact> | null | undefined;
};

type TooltipProps = {
  title: string;
  children: React.ReactElement;
};

// type SingleDataRow = { [key: string]: JSX.Element };
/**
 * 1行に表示する<TD>タグデータを作成
 *
 * @param key キー
 * @param rowData 1行データ
 * @param mailAddressLinkClicked メールアドレスリンクがクリックされた場合の処理
 * @param imeiLinkClicked IMEIリンクがクリックされた場合の処理
 * @returns
 */
const createTdData = (
  t: TFunction,
  localeLanguage: string,
  key: string,
  rowData: TableRowData,
  tooltipEnabled: boolean,
  setTooltipEnabled: (val: boolean) => void,
  imeiLinkClicked?: ((imei: string) => void) | undefined,
  rowIndex = 1,
) => {
  const handleShouldShowTooltip = (event: MouseEvent<Element>) => {
    const element = event.currentTarget as HTMLElement;
    const clientWidth = element.getBoundingClientRect().width;

    // Clone the element
    const clone = element.cloneNode(true) as HTMLElement;

    // Set styles for the clone
    clone.style.visibility = 'hidden';
    clone.style.position = 'absolute';
    clone.style.width = 'auto';

    // Append the clone to the DOM
    element.parentNode?.appendChild(clone);

    // Set the element's overflow to visible
    element.style.overflow = 'visible';

    // Get the scroll width of the clone
    const scrollWidth = clone.getBoundingClientRect().width;

    // Remove the clone from the DOM
    element.parentNode?.removeChild(clone);

    // Set the element's overflow back to hidden
    element.style.overflow = 'hidden';

    if (scrollWidth > clientWidth) {
      setTooltipEnabled(true);
    } else {
      setTooltipEnabled(false);
    }
  };

  const formatSimExpDate = (simExpDate: string): string => {
    const year = parseInt(simExpDate?.split(',')[1], 10);
    if (year >= 9999) {
      return t('deviceInfo.label.unlimitedExpire');
    }

    return simExpDate;
  };

  const StyledTooltip = React.memo<TooltipProps>(
    (props): JSX.Element => (
      <Tooltip
        placement="top"
        title={props.title}
        onMouseEnter={handleShouldShowTooltip}
        disableHoverListener={!tooltipEnabled}
        arrow
        sx={{ marginTop: 0 }}
        PopperProps={{
          onClick: handleTooltipClick,
          modifiers: [
            {
              name: 'offset',
              options: {
                offset: [-7, -7],
              },
            },
          ],
        }}
        componentsProps={{
          tooltip: {
            sx: {
              maxWidth: '240px',
              padding: '8px 15px 9px',
              textAlign: 'center',
              fontWeight: 300,
              fontSize: '12px',
              backgroundColor: '#313133',
              lineHeight: '17px',
              whiteSpace: 'pre-wrap',
            },
          },
        }}
      >
        {props.children}
      </Tooltip>
    ),
  );

  switch (key) {
    case DEVICE_TABLE_DATA_KEY.IMEI: // keyがIMEIの場合はリンク表示
    case TRANS_COUNT_TABLE_DATA_KEY.IMEI:
    case GROUP_DEVICE_LIST_TABLE_DATA_KEY.DEVICE_NUMBER_IMEI:

      return (
        <div
          style={{ color: '#009DE1' } }
        >
          {rowData.data[key]}
        </div>
      );

    case GROUP_LIST_TABLE_DATA_KEY.GROUP_NAME:
      return (
        <StyledTooltip title={rowData.data.group_name as string}>
          <div
            className={styles['link-style-btn']}
            style={{
              height: '20px',
            }}
          >
            <span>{rowData.data.group_name}</span>
          </div>
        </StyledTooltip>
      );
    case GROUP_LIST_TABLE_DATA_KEY.LAST_UPDATED:
      return (
        /*eslint-disable*/
        <Box
          sx={{
            paddingRight: isSafari
              ? '20px'
              : isFirefox && osName !== 'Linux'
              ? '12px'
              : '8px',
          }}
        >
          {rowData.data[key] === 'Invalid Date'
            ? '-'
            : localeLanguage === LOCALE_CODE.JA
            ? dayjs(rowData.data[key]).format('YYYY年MM月DD日')
            : dayjs(rowData.data[key])
                .locale(localeLanguage)
                .format('MMM DD, YYYY')}
        </Box>
      );
    case DEVICE_TABLE_DATA_KEY.DEVICE_NAME:
    case GROUP_DEVICE_LIST_TABLE_DATA_KEY.DEVICE_NAME:
      return (
        <StyledTooltip title={rowData.data.deviceName}>
          <div className={styles['link-style-deviceList']}>
            <span>{rowData.data.deviceName}</span>
          </div>
        </StyledTooltip>
      );
    case GROUP_DEVICE_LIST_TABLE_DATA_KEY.CREATED_DATE:
      return rowData.data.createdDt === 'Invalid Date'
        ? '-'
        : localeLanguage === LOCALE_CODE.JA
        ? dayjs(rowData.data.createdDt).format('YYYY年MM月DD日')
        : dayjs(rowData.data.createdDt)
            .locale(localeLanguage)
            .format('MMM DD, YYYY');
    case TO_LANG_TABLE_DATA_KEY.LANG:
      return (
        <StyledTooltip title={rowData.data.lang}>
          <div className={styles['link-style-deviceList']}>
            <span>{rowData.data.lang}</span>
          </div>
        </StyledTooltip>
      );
    case DEVICE_TABLE_DATA_KEY.STATUS:
      return (
        <div>
          <div
            className={
              localeLanguage === LOCALE_CODE.FR ||
              localeLanguage === LOCALE_CODE.DE
                ? styles['device-list-status']
                : ''
            }
            style={{
              background:
                rowData.data.statusColor &&
                statusColorFn(rowData.data.statusColor.toString()),
              color: rowData.data.statusColor ? 'white' : 'black',
              borderRadius: '16px',
              padding: rowData.data.status ? '4px 10px' : '0px',
              fontSize: '12px',
              display: 'inline-block',
            }}
          >
            {rowData.data.status ? rowData.data.status : '-'}
          </div>
        </div>
      );
    case DEVICE_TABLE_DATA_KEY.GROUP_NAME:
      return (
        <CustomTooltip
          toolTipTitle={rowData.data.groupName}
          maxWidth={260}
          fontSize="14px"
        />
      );
    case DEVICE_TABLE_DATA_KEY.SIM_EXP:
      const simExpDate = simExpiryDateFormat(
        rowData.data.simExpDate.simExpDate,
        t,
        localeLanguage,
      );
      const simSuspended = rowData.data.simExpDate.suspensionFlag === 1 ? 1 : 0;

      const SimSuspensionComponent = () => {
        return (
          <div
            style={{ display: 'flex', flexDirection: 'row', marginLeft: '2px' }}
          >
            <div style={{ marginTop: '-2px' }}>
              <SuspendIcon />
            </div>
            <div
              style={{
                fontSize: '10px',
                fontWeight: 300,
                display: 'flex',
                alignItems: 'center',
                marginLeft: '4px',
                color: '#E1341E',
              }}
            >
              SIM Suspended
            </div>
          </div>
        );
      };

      if (simExpDate.date === ' ') {
        return (
          <div>
            <div
              style={{
                fontSize: '13px',
                display: 'flex',
                flexDirection: 'row',
                alignItems: 'center',
              }}
            >
              <span> -</span>
            </div>
            {simSuspended === 1 && <SimSuspensionComponent />}
          </div>
        );
      }
      if (simExpDate.date === t('common.dateFormet.expired')) {
        return (
          <div>
            <div
              style={{
                color: STATUS_COLOR_CODE.RED,
                fontSize: '13px',
                display: 'flex',
                flexDirection: 'row',
                alignItems: 'center',
              }}
            >
              <ExpiredIcon style={{ marginRight: '6px' }} />
              <span> {formatSimExpDate(simExpDate.date)}</span>
            </div>
            {simSuspended === 1 && <SimSuspensionComponent />}
          </div>
        );
      }
      if (simExpDate.warning) {
        return (
          <div>
            <div
              style={{
                color: STATUS_COLOR_CODE.YELLOW,
                fontSize: '13px',
                display: 'flex',
                flexDirection: 'row',
                alignItems: 'center',
              }}
            >
              <ExpiryWarning style={{ marginRight: '6px' }} />
              <span> {formatSimExpDate(simExpDate.date)}</span>
              {simSuspended === 1 && <SimSuspensionComponent />}
            </div>
          </div>
        );
      }
      return (
        <div>
          <div
            style={{
              color: '#313133',
              fontSize: '13px',
              display: 'flex',
              flexDirection: 'row',
              alignItems: 'center',
            }}
          >
            <span> {formatSimExpDate(simExpDate.date)}</span>
          </div>
          {simSuspended === 1 && <SimSuspensionComponent />}
        </div>
      );
    case DEVICE_TABLE_DATA_KEY.LAST_USED:
      const dateData = getTimeFormat(rowData.data.lastUsed, t);
      if (dateData.date === 'invalid') {
        return (
          <div>
            <div
              style={{
                fontSize: '13px',
                display: 'flex',
                flexDirection: 'row',
                alignItems: 'center',
              }}
            >
              -
            </div>
          </div>
        );
      }
      if (dateData.recentUsed) {
        return (
          <div>
            <div
              style={{
                color: STATUS_COLOR_CODE.GREEN,
                fontSize: '13px',
                display: 'flex',
                flexDirection: 'row',
                alignItems: 'center',
              }}
            >
              <LastUsedTick style={{ marginRight: '6px' }} />
              <p
                style={{
                  whiteSpace: 'pre-wrap',
                  maxWidth: '130px',
                  textAlign: 'left',
                  marginBottom: '0px',
                }}
              >
                {dateData.date}
              </p>
            </div>
          </div>
        );
      }
      if (!dateData.recentUsed) {
        return (
          <div>
            <div
              style={{
                color: STATUS_COLOR_CODE.YELLOW,
                fontSize: '13px',
                display: 'flex',
                flexDirection: 'row',
                alignItems: 'center',
              }}
            >
              <LastUsedWarning style={{ marginRight: '6px' }} />{' '}
              <p
                style={{
                  whiteSpace: 'pre-wrap',
                  maxWidth: '130px',
                  textAlign: 'left',
                  marginBottom: '0px',
                }}
              >
                {dateData.date}
              </p>
            </div>
          </div>
        );
      }
      return <span> </span>;
    case USER_LIST_TABLE_DATA_KEY.USER_NAME:
      const imageLink = rowData.data.imageUrl;
      const nameWord = rowData.data.userName
        .toString()
        .trim()
        .charAt(0)
        .toUpperCase();
      const colorIndex = rowIndex % 16;

      return (
        <Box sx={{ display: 'flex' }}>
          <Box>
            {imageLink ? (
              <img
                alt="pic"
                src={imageLink.toString()}
                style={{ width: '30px', height: '30px', borderRadius: '50%' }}
              />
            ) : (
              <Avatar
                sx={{
                  width: '30px',
                  height: '30px',
                  fontSize: 10,
                  fontWeight: 'bold',
                  bgcolor: colorsArray[colorIndex],
                }}
              >
                {nameWord}
              </Avatar>
            )}
          </Box>
          <StyledTooltip title={rowData.data.userName}>
            <div
              className={styles['link-style-btn']}
              style={{
                color: '#313133',
                margin: 'auto 0px',
                paddingLeft: '12px',
              }}
            >
              <span>{rowData.data.userName}</span>
            </div>
          </StyledTooltip>
        </Box>
      );
    case USER_LIST_TABLE_DATA_KEY.MAIL_ADDRESS:
      return (
        <CustomTooltip
          toolTipTitle={rowData.data.mailAddress}
          maxWidth={240}
          fontSize="14px"
        />
      );

    case USER_LIST_TABLE_DATA_KEY.REG_STATUS:
      return (
        <Box
          sx={{
            marginRight:
              localeLanguage === LOCALE_CODE.JA
                ? '26px'
                : isSafari
                ? '6px'
                : '',
            float: 'right',
            width:
              localeLanguage === LOCALE_CODE.DE
                ? '86px'
                : localeLanguage === LOCALE_CODE.FR
                ? '81px'
                : '80px',
          }}
        >
          <Box
            style={{
              background:
                rowData.data.statusColor &&
                statusColorFn(rowData.data.statusColor.toString()),
            }}
            className={styles['userRegistrationStatus']}
          >
            <Typography sx={{ fontSize: '12px', textAlign: 'center' }}>
              {rowData.data.registrationStatus}
            </Typography>
          </Box>
        </Box>
      );

    case USER_LIST_TABLE_DATA_KEY.DATE_ADDED:
      return (
        <Typography sx={{ fontSize: '13px' }}>
          {rowData.data.dateAdded === 'Invalid Date'
            ? '-'
            : localeLanguage === LOCALE_CODE.JA
            ? dayjs(rowData.data.dateAdded).format('YYYY年MM月DD日')
            : dayjs(rowData.data.dateAdded)
                .locale(localeLanguage)
                .format('MMM DD, YYYY')}
        </Typography>
      );

    case GROUP_LIST_TABLE_DATA_KEY.STATUS_OF_REMOTE_SETTINGS:
      const remoteSettings: {
        dataProtection: number;
        featureLimit: number;
        wifiSetting: number;
        saveHistorySetting: number;
      } = rowData.data.statusOfRemoteSettings;

      // console.log('remoteSettings', rowData.data);

      return (
        <Box
          className={
            isSafari
              ? styles['status-of-remote-settings-isSafari']
              : styles['status-of-remote-settings']
          }
        >
          <IconWithTooltip
            Icon={
              remoteSettings.dataProtection === 1
                ? DataProtectionGroupListSelectedIcon
                : DataProtectionGroupListIcon
            }
            tooltipTitle={t('groupList.tooltip.dataProtection')}
          />
          {/* <IconWithTooltip
            Icon={
              remoteSettings.saveHistorySetting === 1
                ? WifiSettingGroupSaveHistorySelectedIcon
                : WifiSettingGroupSaveHistoryIcon
            }
            tooltipTitle={t('remoteDevice.tooltip.saveTranslation')}
          /> */}
          <IconWithTooltip
            Icon={
              remoteSettings.featureLimit === 1
                ? FeatureLimitGroupListSelectedIcon
                : FeatureLimitGroupListIcon
            }
            tooltipTitle={t('groupList.tooltip.featureLimit')}
          />
          <IconWithTooltip
            Icon={
              remoteSettings.wifiSetting === 1
                ? WifiSettingGroupListSelectedIcon
                : WifiSettingGroupListIcon
            }
            tooltipTitle={t('groupList.tooltip.wifiSet')}
          />
        </Box>
      );

    case TRANS_HISTORY_TABLE_DATA_KEY.TRANSDT:
      return (
        <Typography sx={{ fontSize: '14px' }}>
          {dayjs.utc(rowData.data.transDt).format('YYYY/MM/DD HH:mm')}
        </Typography>
      );
    case TRANS_HISTORY_TABLE_DATA_KEY.FROM_TEXT:
      return (
        <StyledTooltip title={rowData.data.fromText}>
          <div className={styles['link-style-deviceList']}>
            <span>{rowData.data.fromText}</span>
          </div>
        </StyledTooltip>
      );
    case TRANS_HISTORY_TABLE_DATA_KEY.TO_TEXT:
      return (
        <StyledTooltip title={rowData.data.toText}>
          <div className={styles['link-style-deviceList']}>
            <span>{rowData.data.toText}</span>
          </div>
        </StyledTooltip>
      );
    case TRANS_HISTORY_TABLE_DATA_KEY.FROM_LANG:
      return (
        <StyledTooltip title={rowData.data.fromLang}>
          <div className={styles['link-style-deviceList']}>
            <span>{rowData.data.fromLang}</span>
          </div>
        </StyledTooltip>
      );
    case TRANS_HISTORY_TABLE_DATA_KEY.TO_LANG:
      return (
        <StyledTooltip title={rowData.data.toLang}>
          <div className={styles['link-style-deviceList']}>
            <span>{rowData.data.toLang}</span>
          </div>
        </StyledTooltip>
      );
    default:
      return rowData.data[key];
  }
};

// const isMoreThanOneMonthAgo = (date: string | number) => {
//   const currentDate = new Date();
//   const oneMonthAgo = new Date(
//     currentDate.getFullYear(),
//     currentDate.getMonth() - 1,
//     currentDate.getDate(),
//   );
//   return new Date(date) < oneMonthAgo;
// };

// const isMoreThanAYearNext = (date: string | number) => {
//   const currentDate = new Date();
//   const oneYearNext = new Date(
//     currentDate.getFullYear() + 1,
//     currentDate.getMonth(),
//     currentDate.getDate(),
//   );
//   return oneYearNext.getTime() > new Date(date).getTime();
// };
/**
 * テーブルに表示する行単位 コンポーネント
 */
const TableDataRow = React.memo<Props>((props): JSX.Element => {
  const [tooltipEnabled, setTooltipEnabled] = useState(false);
  const { t } = useSwitchLocaleLanguage();
  const { localeLanguage } = useLocale();

  const groupListWidth: { [key: string | number]: string | number } = {
    groupName: 20,
    numberOfAccount: 20,
    numberOfDevice: 22,
    statusOfRemoteSettings: 23,
    modifiedDt: 15,
  };

  const deviceWidth: { [key: string | number]: string | number } = {
    createdDt: 18,
    deviceName: 31,
    productType: 23,
    imei: 28,
  };

  const userWidth: { [key: string | number]: string | number } = {
    userName: 23,
    mailAddress: 23,
    groupName: 15,
    roleName: 15,
    dateAdded: 13,
    registrationStatus: 11,
  };
  const deviceListWidth: { [key: string | number]: string | number } = {
    groupName: 13,
    imei: 12,
    deviceName: 14,
    productType: 11,
    mode: 14,
    status: 16,
    simExp: 10,
    lastUsed: 10,
  };

  const transCountLisWidth: { [key: string | number]: string | number } = {
    imei: 15,
    iccid: 16,
    deviceName: 15,
    productType: 24,
    timesTrans: 14,
  };

  const translationHistoryListWidth: {
    [key: string | number]: string | number;
  } = {
    transdt: 44,
    fromText: 14,
    toText: 14,
    fromLang: 14,
    toLang: 14,
  };
  const navigate = useNavigate();
  const matches = useMediaQuery('(max-width:1820px)');

  const width = (pageSrc: string, match: boolean) => {
    if (pageSrc === 'Device' && match === true) {
      return '1818px';
    }

    if (
      [
        'TransCountList',
        'TransCountListDay',
        'TransFromDay',
        'TransToDay',
        'TransFrom',
        'TransTo',
      ].includes(props.tableSrcPage as string) &&
      props.rows?.length !== 0
    ) {
      return props.columnLength < 5
        ? ''
        : props.columnsWidthSum! > 1820
        ? `${props.columnsWidthSum! - 2}px`
        : '1818px';
    }

    return '';
  };

  return (
    <SimpleBarReact
      className={`${
        props.tableSrcPage === 'Group' ||
        props.tableSrcPage === 'Users' ||
        props.tableSrcPage === 'Device'
          ? 'table-body-simplebar '
          : 'table-body-simplebar-detail'
      } ${props.tableSrcPage === 'Device' ? 'table-body-device-res' : ''}`}
      style={{
        padding: '0px',
        minWidth: width(props.tableSrcPage as string, matches),
      }}
      ref={props.simpleBarRef}
    >
      <div className={styles.tableRowContainer}>
        {props.rows.map((rowData: TableRowData, i) => {
          if (props.tableSrcPage === 'Group') {
            return (
              <tr
                key={rowData.id}
                className={`${styles.groupTableDataRow} ${styles.tableDataRow}`}
                onClick={() => {
                  if (props.clickDisable) {
                    return;
                  }

                  // GA tags
                  sendGAEvent(
                    'select_list',
                    'list_number',
                    'select_group_select',
                  );
                  sendGAEvent(
                    'select_list',
                    'select_group_select_number',
                    rowData.id.toString(),
                  );

                  navigate(`/groups/groupdetail/${rowData.id}`);
                }}
              >
                {Object.keys(rowData.data).map((key, index) => {
                  const indexStr = String(index);

                  return (
                    <td
                      key={`${rowData.data[key]}${indexStr}`} // keyにindexのみを設定できないので表示内容も設定(Reactのルール)
                      style={{
                        width: `${groupListWidth[key]}%`,
                        opacity:
                          props.isCallingApi && !props.dataShow ? '.25' : '',
                        // paddingRight:
                        //   index === Object.keys(rowData.data).length - 1 &&
                        //   isSafari
                        //     ? '18px'
                        //     : '',
                      }}
                    >
                      <Box>
                        {createTdData(
                          t,
                          localeLanguage,
                          key,
                          rowData,
                          tooltipEnabled,
                          setTooltipEnabled,
                          props.imeiLinkClicked,
                        )}
                      </Box>
                    </td>
                  );
                })}
              </tr>
            );
          }
          if (props.tableSrcPage === 'Device') {
            return (
              <tr
                key={rowData.id}
                className={`${styles.userTableDataRow} ${styles.tableDataRow}`}
                onClick={() => {
                  if (props.clickDisable) {
                    return;
                  }
                  if (props.imeiLinkClicked !== undefined) {
                    props.imeiLinkClicked(rowData.id.toString());
                  }
                }}
              >
                {Object.keys(rowData.data)
                  .filter((x) => x !== 'statusColor')
                  .map((key, index) => {
                    const indexStr = String(index);

                    return (
                      <td
                        key={`${rowData.data[key]}${indexStr}`}
                        style={{
                          width: `${deviceListWidth[key]}%`,
                          opacity:
                            props.isCallingApi && !props.dataShow ? '.25' : '',
                          paddingRight:
                            key === DEVICE_TABLE_DATA_KEY.SIM_EXP ||
                            key === DEVICE_TABLE_DATA_KEY.LAST_USED
                              ? 0
                              : '55px',
                        }}
                      >
                        <Box>
                          {createTdData(
                            t,
                            localeLanguage,
                            key,
                            rowData,
                            tooltipEnabled,
                            setTooltipEnabled,
                            props.imeiLinkClicked,
                            i,
                          )}
                        </Box>
                        {/* {console.log(key)} */}
                      </td>
                    );
                  })}
              </tr>
            );
          }

          if (props.tableSrcPage === 'Users') {
            return (
              <tr
                key={rowData.id}
                className={`${styles.userTableDataRow} ${styles.tableDataRow}`}
                onClick={() => {
                  if (props.clickDisable) {
                    return;
                  }
                  navigate(PAGE_PATH_NAME.USER_DETAIL, {
                    state: {
                      mailAddress: rowData.id,
                      accountStatus: rowData.data?.registrationStatus,
                    },
                  });
                }}
              >
                {Object.keys(rowData.data)
                  .filter(
                    (data) => data !== 'statusColor' && data !== 'imageUrl',
                  )
                  .map((key, index) => {
                    const indexStr = String(index);

                    return (
                      <td
                        key={`${rowData.data[key]}${indexStr}`}
                        style={{
                          width: `${userWidth[key]}%`,
                          opacity:
                            props.isCallingApi && !props.dataShow ? '.25' : '',
                        }}
                      >
                        <Box
                          sx={{
                            '& p': {
                              marginTop: '1px',
                            },
                          }}
                        >
                          {createTdData(
                            t,
                            localeLanguage,
                            key,
                            rowData,
                            tooltipEnabled,
                            setTooltipEnabled,
                            props.imeiLinkClicked,
                            i,
                          )}
                        </Box>
                      </td>
                    );
                  })}
              </tr>
            );
          }

          if (props.tableSrcPage === 'GroupDevice') {
            return (
              <tr
                key={rowData.id}
                className={`${styles.deviceTableDataRow} ${styles.tableDataRow}`}
                onClick={() => {
                  if (props.imeiLinkClicked !== undefined) {
                    props.imeiLinkClicked(rowData.id.toString());
                  }
                }}
              >
                {Object.keys(rowData.data).map((key, index) => {
                  const indexStr = String(index);

                  return (
                    <td
                      key={`${rowData.data[key]}${indexStr}`} // keyにindexのみを設定できないので表示内容も設定(Reactのルール)
                      style={{
                        width: `${deviceWidth[key]}%`,
                        opacity:
                          props.isCallingApi && !props.dataShow ? '.25' : '',
                        paddingRight:
                          index === Object.keys(rowData.data).length - 1 &&
                          localeLanguage === LOCALE_CODE.JA
                            ? '0px'
                            : index === Object.keys(rowData.data).length - 1 &&
                              isSafari
                            ? '24px'
                            : index === Object.keys(rowData.data).length - 1 &&
                              isFirefox &&
                              (osName === 'Mac' || osName === 'Windows')
                            ? '13px'
                            : '',
                      }}
                    >
                      <Box>
                        {createTdData(
                          t,
                          localeLanguage,
                          key,
                          rowData,
                          tooltipEnabled,
                          setTooltipEnabled,
                          props.imeiLinkClicked,
                          i,
                        )}
                      </Box>
                    </td>
                  );
                })}
              </tr>
            );
          }
          if (props.tableSrcPage === 'TransHistory') {
            return (
              <tr
                key={rowData.id}
                className={`${styles.transHistoryTableDataRow} ${styles.tableDataRow}`}
              >
                {Object.keys(rowData.data).map((key, index) => {
                  const indexStr = String(index);

                  return (
                    <td
                      key={`${rowData.data[key]}${indexStr}`}
                      style={{
                        width: `${translationHistoryListWidth[key]}%`,
                        opacity:
                          props.isCallingApi && !props.dataShow ? '.25' : '',
                      }}
                    >
                      <Box>
                        {createTdData(
                          t,
                          localeLanguage,
                          key,
                          rowData,
                          tooltipEnabled,
                          setTooltipEnabled,
                          props.imeiLinkClicked,
                          i,
                        )}
                      </Box>
                    </td>
                  );
                })}
              </tr>
            );
          }
          if (
            ['TransFrom', 'TransTo', 'TransFromDay', 'TransToDay'].includes(
              props.tableSrcPage as string,
            )
          ) {
            return (
              <tr
                key={rowData.id}
                className={`${styles.tableDataRow} ${styles.reportDataRow}`}
              >
                {Object.keys(rowData.data)
                  .filter((x) => x !== 'statusColor')
                  .map((key, index) => {
                    const indexStr = String(index);

                    return (
                      <td
                        key={`${rowData.data[key]}${indexStr}`}
                        style={{
                          minWidth: `${
                            props.columns && props.columns[index]?.width
                          }`,
                          opacity:
                            props.isCallingApi && !props.dataShow ? '.25' : '',
                          // paddingRight: '15px',
                        }}
                      >
                        <Box>
                          {createTdData(
                            t,
                            localeLanguage,
                            key,
                            rowData,
                            tooltipEnabled,
                            setTooltipEnabled,
                            props.imeiLinkClicked,
                            i,
                          )}
                        </Box>
                        {/* {console.log(rowData)} */}
                      </td>
                    );
                  })}
              </tr>
            );
          }
          if (
            ['TransCountList', 'TransCountListDay'].includes(
              props.tableSrcPage as string,
            )
          ) {
            return (
              <tr
                key={rowData.id}
                className={`${styles.userTableDataRow} ${styles.tableDataRow}`}
                onClick={() => {
                  if (props.imeiLinkClicked !== undefined) {
                    props.imeiLinkClicked(rowData.id.toString());
                  }
                }}
              >
                {Object.keys(rowData.data)
                  .filter((x) => x !== 'statusColor')
                  .map((key, index) => {
                    const indexStr = String(index);
                    return (
                      <td
                        key={`${rowData.data[key]}${indexStr}`}
                        style={{
                          minWidth: `${
                            props.columns && props.columns[index]?.width
                          }`,
                          opacity:
                            props.isCallingApi && !props.dataShow ? '.25' : '',
                          paddingRight: '0px',
                        }}
                      >
                        <Box>
                          {createTdData(
                            t,
                            localeLanguage,
                            key,
                            rowData,
                            tooltipEnabled,
                            setTooltipEnabled,
                            props.imeiLinkClicked,
                            i,
                          )}
                        </Box>
                        {/* {console.log(key)} */}
                      </td>
                    );
                  })}
              </tr>
            );
          }
        })}
      </div>
    </SimpleBarReact>
  );
});

export default TableDataRow;
